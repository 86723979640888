//Mat Sticky Table CSS
.mat-table-listing {
  height: 100%;
  max-width: 100%;
  overflow: auto;
}

.mat-mdc-header-cell,
.mat-mdc-header-cell.mat-mdc-table-sticky {
  background: var(--table-head-bg) !important;
  opacity: 1;
  color: var(--text-color-dark) !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0.5rem !important;
}

.mat-mdc-table .mdc-data-table__header-row {
  z-index: 9 !important;
  position: relative;
}

.mat-mdc-table {
  background-color: var(--white) !important;

  .mat-mdc-header-row{
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      border-color: var(--white);
    }
  
    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
      border-color: var(--white) !important;
    }
  }
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  min-width: 15%;
  box-sizing: border-box;
  border-bottom: 0 !important;
  font-weight: 600 !important;
}

.mat-mdc-table mat-row.mat-mdc-row {
  height: 60px !important;
}

.mat-mdc-cell.mat-mdc-table-sticky  {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.mat-mdc-header-row,
.mat-mdc-row {
  min-width: 1400px;
  // margin-bottom: 0.8rem;
}

.mat-mdc-row:nth-child(odd) {
  background: var(--fc-background) !important;
}

.mat-mdc-table-sticky-border-elem-top {
  border-bottom: 0px solid midnightblue;
}

.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid #ddd;
}

.mat-mdc-table-sticky-border-elem-bottom {
  border-top: 1px solid midnightblue;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid #ddd;
}

.mat-column-name {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 20% !important;
  width: 20% !important;
  overflow-wrap: break-word;
}

.mat-column-srvcaccounts {
  flex: 0 0 25% !important;
}

.mat-column-role {
  text-transform: capitalize;
}

.mat-column-action {
  min-width: 10%;
  text-align: center !important;
}