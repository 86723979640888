// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$smartvault-ui-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// :root {

// }

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($smartvault-ui-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($smartvault-ui-theme);
/* Common Styles Used */

@import "src/styles.scss";
@import "src/assets/styles/mat-table.scss";

:root {
  @include mat.all-component-themes($smartvault-ui-theme);

  --font-family: "Nunito Sans", sans-serif !important;

  --body-font-size: 0.8125rem;
  --body-font-weight: 600;
  --body-line-height: 1.4;
  --body-letter-spacing: 0px;
  --body-color: var(--white);
  --body-bg: #f5f7fa;

  --white: #fff;
  --black: #000;
  --blue: #348ffb;
  --default: #f3f4f6;

  --gray: #646464;
  --gray-light: #727272;
  --gray-dark: #343a40;
  --gray-100: #f9f9f9;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;

  --primary: #782891;
  --primary-hover: var(--secondary);
  --primary-active: var(--secondary);

  --secondary: #07123f;
  --secondary-hover: var(--primary);
  --secondary-active: var(--primary);
  --secondary-light: #cdcfd8;
  --secondary-dark: #384165;

  --success: #10b981;
  --warning: #f89c29;
  --danger: #f00;
  --deep-blue: #0a4e7e;
  --blue: #377dff;
  --pink: #d463f0;
  --info: #09a5be;
  --light: #e7eaf3;
  --dark: #212529;
  --label: #65758a;
  --iris-blue: #00d1bd;
  --dark-magenta: #782891;

  // RGB Color CSS Variables
  --primary-rgb: 120, 40, 145;
  --secondary-rgb: 7, 18, 63;
  --success-rgb: 67, 208, 57;
  --warning-rgb: 248, 156, 41;
  --danger-rgb: 255, 0, 0;
  --deep-blue-rgb: 10, 78, 126;
  --blue-rgb: 55, 125, 255;
  --pink-rgb: 212, 99, 240;
  --info-rgb: 9, 168, 186;
  --label-rgb: 101, 117, 138;
  --iris-blue-rgb: 0, 209, 189;
  --dark-magenta-rgb: 120, 40, 145;

  // Alert CSS Variables
  --alert-1: #6fcf97;
  --alert-2: #eb5757;
  --alert-3: #f2c94c;
  --alert-4: #fdd7d7;
  --alert-5: #963a3a;

  // Border CSS Variables
  --border: #f2f2f2;
  --border-2: #e6ebf2;
  --border-3: #a3b6cc;
  --label-border: #ccc;

  // Background CSS Variables
  --background: #dde4ed;
  --background-2: #a3b6cc;
  --background-3: #f6f8fa;
  --background-4: #f5f5f5;
  --background-5: var(--primary);

  --box-shadow: #dde5ed;

  // Gradient CSS Variables
  --gradient: linear-gradient(134deg, var(--primary) 0%, var(--secondary) 100%);
  --gradient-hover: linear-gradient(
    134deg,
    var(--secondary) 0%,
    var(--primary) 100%
  );

  // Form Controls CSS Variables
  --fc-border-radius: 0.438rem;
  --fc-border-color: #cdcfd8;
  --fc-border: 1px solid #cdcfd8;
  --fc-box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15);
  --fc-background: #fbfdff;
  --fc-select-bg: #fbfdff;
  --fc-placeholder-text: #65758a;
  --fc-input-placeholder: #65758a;
  --fc-icon: #65758a;
  --fc-height: 4px;
  --fc-select-open-border-radius: 0.438rem;
  --form-label-color: #384165;
  --form-label-fs: 0.688rem;

  // Card CSS Variables
  --card-background: #f3f4f6;
  --card-border-radius: 0.438rem;
  --card-border-color: #cdcfd8;
  --card-border: 1px solid #cdcfd8;
  --card-box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15);

  // Modal Pop-up CSS Variables
  --modal-border-radius: 7px;
  --modal-box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15);

  // Badge CSS Variables
  --badge-border-radius: 0.438rem;

  --tooltip-bg: #364655;
  --border-radius: 0.438rem;
  --alert-border-radius: 0.438rem;
  --switch-border-radius: 50rem;

  // Button CSS Variables
  --button-bg: var(--primary);
  --btn-border: 0.0625rem solid transparent;
  --btn-box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5);
  --btn-border-radius: 0.438rem;

  // Font Weight CSS Variables
  --fw-1: 100;
  --fw-2: 200;
  --fw-3: 300;
  --fw-4: 400;
  --fw-5: 500;
  --fw-6: 600;
  --fw-7: 700;
  --fw-8: 800;
  --fw-9: 900;

  // Gap css in rem units
  --gap-8: 0.5rem;
  --gap-10: 0.625rem;
  --gap-12: 0.75rem;
  --gap-14: 0.875rem;
  --gap-16: 1rem;
  --gap-18: 1.125rem;
  --gap-20: 1.25rem;

  // Text Color CSS Variables
  --text-color-white: #fff;
  --text-color-light: #65758a;
  --text-color-dark: #07123f;
  --text-color-dark-2: #282c3f;
  --text-active: #e7f4f2;
  --caption-color: #8c98a4;
  --code-text-color: #e83e8c;

  --text-label: #6d6d6d;
  --pg-btn-text: #c6c6c6;
  --pg-btn-bg: #c4d0df;

  // Tables CSS Variables
  --table-bg: #fff;
  --table-head-bg: #f3f4f6;
  --th-font-size: 0.688rem;
  --table-head-dark-bg: #364655;
  --table-even-row-bg: #eff6ff;
  --table-border-rgba: rgba(231, 234, 243, 0.7);
  --table-border-radius: 0.438rem;
  --table-border: 0.0625rem solid var(--table-border-rgba);
  --table-cell-height: 3rem;
  --table-body-row-bg: #f9f9f9;

  // Material CSS
  --mdc-text-button-label-text-color: var(var(--text-color-dark));
}

*,
*::before,
*::after {
  line-height: normal;
  font-family: var(--font-family) !important;
}

.material-icons {
  font-family: "Material Icons" !important;
}

::selection {
  color: var(--white);
  background-color: var(--primary);
}

::placeholder {
  font-size: 0.785rem;
  font-weight: 400;
  letter-spacing: var(--body-letter-spacing);
  color: var(--fc-placeholder-text);
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

html {
  position: relative;
  height: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  position: relative;
  font-family: var(--font-family) !important;
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  letter-spacing: var(--body-letter-spacing);
  color: var(--text-color-dark);
  height: 100%;
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 1rem 0;
  border-top: 0.0625rem solid var(--light);
}

main {
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;

  .content {
    height: inherit;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 800;
  line-height: 1.5;
  // color: var(--text-color-dark);
}

.mark,
mark {
  padding: 0.2em;
  background-color: rgba(var(--blue-rgb), 0.1);
}

p {
  color: var(--text-color-light);
  font-weight: 600;
  margin: 0;
}

abbr[data-original-title],
abbr[title] {
  border-bottom: 0;
}

a {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: var(--primary-hover);
    text-decoration: none;
  }
}

code,
kbd,
pre,
samp {
  font-family: var(--font-family);
}

pre code {
  -ms-overflow-style: scrollbar;
  color: var(--text-color-dark);
}

code {
  color: var(--code-text-color);
}

caption {
  color: var(--caption-color);
  caption-side: bottom;
}

.img-thumbnail {
  background-color: var(--white);
  border: 0.0625rem solid var(--light);
  border-radius: 5rem;
}

.figure-caption {
  color: var(--caption-color);
}

.text-sm {
  font-size: 12px !important;
}

/* Input Validations*/
input.is-invalid {
  border-color: var(--danger) !important;
}

/* END Input Validations*/

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border-color: var(--danger) !important;

  &::placeholder {
    color: var(--danger) !important;
  }
}

.is-invalid {
  border-color: var(--danger) !important;
}

// Width CSS
.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }
  
  .w-xl-80 {
    width: 80% !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }
}
// END Width CSS

// Height CSS
.h-auto {
  height: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-75 {
  height: 75% !important;
}

.h-85 {
  height: 85% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}
// Height CSS

.rotate-45 {
  transform: rotate(45deg);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.btn-style {
  background: var(--primary) !important;
  font-size: 14px !important;
}

.mat-form-field,
.mat-tooltip {
  font-family: var(--font-family) !important;
}

// Tooltip
.mat-tooltip,
.mat-mdc-tooltip {
  background-color: var(--secondary);
  padding: 10px;
  font-size: 0.8em;
  width: auto;
  white-space: pre-line;
}

.mdc-tooltip__surface {
  max-height: 100px !important;
  overflow-y: auto !important;
}

.mat-tab-labels {
  background: var(--white) !important;
  border-top: 2px solid var(--border) !important;

  .mat-tab-label-active {
    color: var(--primary-hover) !important;
    opacity: 1 !important;
  }
}

.mat-form-field {
  .mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    border: none !important;

    .mat-form-field-flex {
      border: none !important;
      outline: none !important;
      align-items: center !important;
    }
  }
}

/* Global Mat-drawer CSS */
.mat-drawer-content,
.mat-drawer-inner-container {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
}

/* END Global Mat-drawer CSS */

// Empty Image
.emptyImg {
  margin-top: 20px;
  width: 400px;
  text-align: center;
}

// END Empty Image

.chat-input .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  flex-flow: row;
  max-width: calc(var(--bot-width) - 80px);
  overflow: hidden;
  overflow-x: auto;
}

// Mat-Stepper
.mat-step-label {
  padding: 0 !important;
}

.mat-step-icon {
  height: 30px !important;
  width: 30px !important;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: var(--font-family) !important;
  background: transparent !important;
}

.mat-horizontal-stepper-header {
  padding: 10px !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
  overflow: inherit !important;
}

.mat-stepper-horizontal-line {
  top: 25px !important;
}
// END Mat-Stepper

.mat-menu-panel {
  background-color: var(--white);
}

/* Mat-toggle-slider */
.mat-slide-toggle-bar {
  background: var(--label-border);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background: var(--primary) !important;
}

.mat-slide-toggle-thumb {
  background: var(--white) !important;
  border: 1px solid var(--label-border);
}

/* END Mat-toggle-slider */

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--primary) !important;
}

// Avatar Image
.avatarImg-wrapper {
  box-sizing: border-box !important;

  .avatarImg {
    label,
    .uploadedAvatarImg,
    .removeImg {
      height: 100px !important;
      width: 100px !important;
    }

    label {
      border: 1px solid var(--label-border) !important;
    }
  }

  .removeImg,
  #addOrgRemoveImg {
    top: 0%;
    font-size: 24px !important;

    fa-icon {
      display: none !important;
    }

    &:hover {
      background: rgb(8, 0, 60, 0.65);

      fa-icon {
        cursor: pointer;
        display: block !important;
      }
    }
  }
}
// END Avatar Image

// Form Styles
label,
.label {
  font-size: 0.688rem;
  // margin-bottom: 0.3125rem;
  color: var(--secondary-dark);
}

input {
  width: 100%;
  border: var(--fc-border);
  outline: none;
}

.input-bottom {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--primary) !important;
  border-radius: 0 !important;
}

.ng-option input,
.ng-optgroup input {
  width: auto !important;
}

.pswd_icon {
  font-size: 18px !important;
  position: absolute;
  right: 20px;
  top: 28%;
  cursor: pointer;
}
// END Form Styles

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: rgba(52, 143, 251) !important;
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.2 !important;
}

// Checkbox css
.mat-mdc-checkbox {
  margin-top: 5px !important;
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc(
    (var(--mdc-checkbox-state-layer-size, 20px) - 18px) / 2
  ) !important;
  margin: calc(
    (
        var(--mdc-checkbox-state-layer-size, 20px) -
          var(--mdc-checkbox-state-layer-size, 20px)
      ) / 2
  ) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size, 20px) - 18px) / 2) !important;
  left: calc(
    (var(--mdc-checkbox-state-layer-size, 20px) - 18px) / 2
  ) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  width: auto !important;
  height: 100% !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  border-radius: 5px !important;
}

.mdc-form-field > label {
  color: var(--black) !important;
}

.mat-mdc-checkbox .mdc-form-field > label {
  margin: 0px !important;
}

.mat-mdc-checkbox-touch-target {
  width: 20px !important;
  height: 20px !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-radius: 5px !important;
}
// END Checkbox css

.authform_phone {
  .ng-select {
    .ng-select-container {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }

  .ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-top-right-radius: 0em !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

// Modal Popup Settings
.modal .modal-dialog {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: var(--modal-border-radius);
  outline: 0;

  .modal-body {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 10px;
    }
  }
}

.mat-drawer-content,
.modal-content .modal-body {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mat-drawer-content::-webkit-scrollbar,
.modal-content .modal-body::-webkit-scrollbar {
  width: 3px;
}

.mat-drawer-content::-webkit-scrollbar-track {
  background: var(--white);
  width: 0px;
}

.mat-drawer-content::-webkit-scrollbar-thumb,
.modal-content .modal-body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: var(--modal-border-radius);
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: inherit !important;
  height: inherit !important;
}
// END Modal Popup Settings

/* Cursor Styles */
.alias {
  cursor: alias;
}

.all-scroll {
  cursor: all-scroll;
}

.auto {
  cursor: auto;
}

.cell {
  cursor: cell;
}

.col-resize {
  cursor: col-resize;
}

.context-menu {
  cursor: context-menu;
}

.copy {
  cursor: copy;
}

.crosshair {
  cursor: crosshair;
}

.default {
  cursor: default;
}

.e-resize {
  cursor: e-resize;
}

.ew-resize {
  cursor: ew-resize;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.help {
  cursor: help;
}

.move {
  cursor: move;
}

.n-resize {
  cursor: n-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nesw-resize {
  cursor: nesw-resize;
}

.ns-resize {
  cursor: ns-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.nwse-resize {
  cursor: nwse-resize;
}

.no-drop {
  cursor: no-drop;
}

.none {
  cursor: none;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.row-resize {
  cursor: row-resize;
}

.s-resize {
  cursor: s-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.text {
  cursor: text;
}

.w-resize {
  cursor: w-resize;
}

.wait {
  cursor: wait;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

/* END Cursor Styles */

/* Custom Style */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.alert_dot {
  position: absolute;
  top: -8px;
  right: 8px;
  background: var(--gradient);
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

legend {
  display: block;
  font-size: calc(1.275rem + 0.3vw);
  color: inherit;
  white-space: normal;
}

template {
  display: none;
}

.ngx-slider .ngx-slider-pointer {
  width: 16px !important;
  height: 16px !important;
  top: -6px !important;
  background-color: var(--primary) !important;

  &::after {
    width: 6px !important;
    height: 6px !important;
    top: 5px !important;
    left: 5px !important;
  }
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: var(--white) !important;
}

.ngx-slider .ngx-slider-selection {
  background: var(--primary) !important;
}

[role="button"] {
  cursor: pointer;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  appearance: none;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

// List CSS
.list-style-dots {
  list-style: disc;
}

.list-inline-flex {
  display: flex;
  align-items: center;
  gap: var(--gap-10);
}

.list-divider {
  li:not(:last-child) {
    border-right: 0.0625rem solid var(--secondary-light);
    padding-right: var(--gap-10);
  }
}

.list-inline-item:not(:last-child) {
  margin-right: 0;
}

.list-inline-m-1 {
  margin-left: -0.25rem !important;
}

.list-inline-m-1 .list-inline-item {
  margin: 0.25rem !important;
}

.list-unstyled-py-2 > li:not(:last-child) {
  padding-bottom: 0.5rem;
}

.list-unstyled-py-2 .list-unstyled-py-2 > li:first-child {
  padding-top: 0.5rem;
}

.list-unstyled-py-2 .list-unstyled-py-2 > li:last-child {
  padding-bottom: 0;
}

.list-unstyled-py-3 > li:not(:last-child) {
  padding-bottom: 1rem;
}

.list-unstyled-py-3 .list-unstyled-py-3 > li:first-child {
  padding-top: 1rem;
}

.list-unstyled-py-3 .list-unstyled-py-3 > li:last-child {
  padding-bottom: 0;
}

.list-unstyled-py-4 > li:not(:last-child) {
  padding-bottom: 1.5rem;
}

.list-unstyled-py-4 .list-unstyled-py-4 > li:first-child {
  padding-top: 1.5rem;
}

.list-unstyled-py-4 .list-unstyled-py-4 > li:last-child {
  padding-bottom: 0;
}

.list-comment {
  padding-left: 0;
  list-style: none;
}

.list-comment-item:not(:first-child) {
  border-top: 0.0625rem solid var(--light);
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.list-separator {
  margin-bottom: 0;
}

.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}

.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: calc(50% - 0.15625rem);
  right: 0.84375rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #bdc5d1;
  border-radius: var(--border-radius);
  content: "";
}

.list-separator .list-separator-link {
  color: var(--text-color-light);
}

.list-separator .list-separator-link:hover {
  color: var(--primary-hover);
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.list-group-icon {
  width: 1.25rem;
  text-align: center;
  margin-right: 0.25rem;
}

.list-group-sm .list-group-item {
  font-size: 0.8125rem;
  padding: 0.375rem 0.625rem;
}

.list-group-lg .list-group-icon {
  font-size: 1.3125rem;
  width: 2.1875rem;
  margin-right: 0.5rem;
}

.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  display: block;
  color: var(--text-color-light);
  padding-left: 1.75rem;
  margin: 0.5rem;
}

.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-top: 0.125rem;
}

.list-checked-item[hover]:hover {
  color: var(--primary);
}

[class*="list-checked-bg-"] .list-checked-item::before,
[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-sm .list-checked-item {
  padding-left: 1.25rem;
}

.list-checked-sm .list-checked-item::before {
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem 0.75rem;
  margin-top: 0.3125rem;
}

.list-checked-sm[class*="list-checked-bg-"] .list-checked-item::before,
.list-checked-sm[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.375rem;
}

.list-checked-lg .list-checked-item {
  padding-left: 2.25rem;
}

.list-checked-lg .list-checked-item::before {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 1.25rem 1.25rem;
  margin-top: 0;
}

.list-checked-lg[class*="list-checked-bg-"] .list-checked-item::before,
.list-checked-lg[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.125rem;
}

.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23556ff6' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23556ff6' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2371869d' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2371869d' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300c9a7' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2300c9a7' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300c9db' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%2300c9db' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f5ca99' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23f5ca99' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ed4c78' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23ed4c78' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f9fafc' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23f9fafc' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 100 90' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z' /%3E%3C/svg%3E%0A");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23132144' opacity='.1' d='M55,110L55,110C24.6,110,0,85.4,0,55l0,0C0,24.6,24.6,0,55,0l0,0c30.4,0,55,24.6,55,55l0,0 C110,85.4,85.4,110,55,110z'/%3E%3Cpath fill='%23132144' d='M81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2 l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E");
}
// END List CSS

.col-sm-divider > :not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(var(--black-rgb), 0.7);
  content: "";
}

// Table Styles
.table {
  color: var(--text-color-light);
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
}

.table thead th {
  text-transform: capitalize;
  font-size: var(--th-font-size);
  font-weight: 600;
  vertical-align: bottom;
}

.table tfoot td,
.table tfoot th {
  color: var(--text-color-dark);
  font-weight: 600;
  border-top: 0.0625rem solid var(--table-border-rgba);
}

.table .btn {
  white-space: nowrap;
}

.table-sm {
  font-size: 0.8125rem;
}

.table-lg td,
.table-lg th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

.table-align-middle tbody td {
  vertical-align: middle;
}

.table-text-center td,
.table-text-center th {
  text-align: center;
}

.table-column-right-aligned {
  text-align: right;
}

.table-column-pr-0 {
  padding-right: 0 !important;
}

.table-column-pl-0 {
  padding-left: 0 !important;
}

.table-thead-bordered thead th {
  border-top: 0.0625rem solid var(--table-border-rgba);
  border-bottom: 0.0625rem solid var(--table-border-rgba);
}

.table-input-group {
  min-width: 14rem;
}

.table-progress {
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 5rem;
  height: 0.25rem;
}

.table tbody + tbody {
  border-top: 0.125rem solid var(--table-border-rgba);
}

.table-sm td,
.table-sm th {
  padding: 0.5rem;
}

.table-bordered {
  border: 0.0625rem solid var(--table-border-rgba);
}

.table-bordered td,
.table-bordered th {
  border: 0.0625rem solid var(--table-border-rgba);
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 0.125rem;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--white);
}

.table-hover tbody tr:hover {
  color: var(--text-color-light);
  background-color: rgba(231, 234, 243, 0.4);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #c7dbff;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #97bbff;
}

.table-hover .table-primary:hover {
  background-color: #aecbff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aecbff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d7dde4;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b5c0cc;
}

.table-hover .table-secondary:hover {
  background-color: #c8d0da;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8d0da;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #b8f0e6;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecdf;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #b8f0f5;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #7ae3ec;
}

.table-hover .table-info:hover {
  background-color: #a2ebf2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2ebf2;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fcf0e2;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fae3ca;
}

.table-hover .table-warning:hover {
  background-color: #fae4cb;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae4cb;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #facdd9;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #f6a2b9;
}

.table-hover .table-danger:hover {
  background-color: #f8b6c7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f8b6c7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfefe;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fcfcfd;
}

.table-hover .table-light:hover {
  background-color: #ecf6f6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf6f6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #bdc1cb;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #848c9e;
}

.table-hover .table-dark:hover {
  background-color: #afb4c0;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #afb4c0;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(231, 234, 243, 0.4);
}

.table-hover .table-active:hover {
  background-color: rgba(214, 219, 235, 0.4);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(214, 219, 235, 0.4);
}

.table .thead-dark th {
  color: var(--white);
  background-color: #132144;
  border-color: #1b3062;
}

.table .thead-light th {
  color: var(--text-color-light);
  background-color: #f8fafd;
  border-color: var(--table-border-rgba);
}

.table-dark {
  color: var(--white);
  background-color: #132144;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #1b3062;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(var(--black-rgb), 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: var(--white);
  background-color: rgba(var(--black-rgb), 0.075);
}

.table-responsive {
  display: block;
  width: 100%;
}

.table-responsive > .table-bordered {
  border: 0;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
  }

  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .table-nowrap td,
  .table-nowrap th {
    white-space: normal;
  }
}
// END Table Styles

// Form Controls
.form-control {
  padding: 0.5em 1em;
  height: 42px;
  min-height: var(--fc-height) !important;
  font-size: var(--body-font-size);
  font-weight: var(--fw-6);
  color: var(--text-color-dark);
  background: var(--fc-background) !important;
  border: var(--fc-border);
  border-radius: var(--fc-border-radius) !important;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--text-color-dark);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: var(--success);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.form-control:disabled {
  background-color: var(--background) !important;
}

.form-control:focus {
  color: var(--text-color-dark);
  background-color: var(--white);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.25rem rgba(var(--primary-rgb), 0.1);
}

.form-control::-webkit-input-placeholder {
  color: var(--fc-input-placeholder);
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: var(--fc-input-placeholder);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: var(--fc-input-placeholder);
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: var(--fc-input-placeholder);
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}

.form-control-plaintext {
  color: var(--text-color-light);
  border-width: 0.0625rem 0;
}

.form-control-sm {
  height: calc(1.5em + 1rem);
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

textarea.form-control {
  height: auto;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control[multiple],
select.form-control[size] {
  height: auto;
}

select.form-control:focus::-ms-value {
  color: var(--text-color-dark);
  background-color: var(--white);
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.60938rem;
  padding-bottom: 0.60938rem;
}

.col-form-label-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.col-form-label-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.form-text {
  color: var(--caption-color);
  display: block;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  font-size: inherit;
  display: flex;
  align-items: center;
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.form-check-input:focus {
  border-color: var(--primary) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--primary-rgb), 0.25) !important;
}

.form-check-input {
  position: absolute;
  cursor: pointer;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: var(--caption-color);
}

.form-check-label {
  margin-bottom: 0;
  color: var(--text-color-dark);
  font-size: inherit;
  padding-left: var(--gap-8);
}
// END Form Controls

// Form Inline CSS
.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
// END Form Inline CSS

// List Group CSS
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--border-radius);
}

.list-group-item-action {
  width: 100%;
  color: var(--text-color-light);
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: var(--text-color-light);
  text-decoration: none;
  background-color: #f9fafc;
}

.list-group-item-action:active {
  color: var(--text-color-light);
  background-color: #f8fafd;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: var(--white);
  border: 0.0625rem solid var(--light);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--caption-color);
  pointer-events: none;
  background-color: var(--white);
}

.list-group-item.active {
  z-index: 2;
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 5rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 5rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d4185;
  background-color: #c7dbff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #1d4185;
  background-color: #aecbff;
}

.list-group-item-primary.list-group-item-action.active {
  color: var(--white);
  background-color: #1d4185;
  border-color: #1d4185;
}

.list-group-item-secondary {
  color: #3b4652;
  background-color: #d7dde4;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #3b4652;
  background-color: #c8d0da;
}

.list-group-item-secondary.list-group-item-action.active {
  color: var(--white);
  background-color: #3b4652;
  border-color: #3b4652;
}

.list-group-item-success {
  color: #006957;
  background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #006957;
  background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
  color: var(--white);
  background-color: #006957;
  border-color: #006957;
}

.list-group-item-info {
  color: #006972;
  background-color: #b8f0f5;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #006972;
  background-color: #a2ebf2;
}

.list-group-item-info.list-group-item-action.active {
  color: var(--white);
  background-color: #006972;
  border-color: #006972;
}

.list-group-item-warning {
  color: #7f6950;
  background-color: #fcf0e2;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #7f6950;
  background-color: #fae4cb;
}

.list-group-item-warning.list-group-item-action.active {
  color: var(--white);
  background-color: #7f6950;
  border-color: #7f6950;
}

.list-group-item-danger {
  color: #7b283e;
  background-color: #facdd9;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #7b283e;
  background-color: #f8b6c7;
}

.list-group-item-danger.list-group-item-action.active {
  color: var(--white);
  background-color: #7b283e;
  border-color: #7b283e;
}

.list-group-item-light {
  color: #818283;
  background-color: #f9fafc;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818283;
  background-color: #ecf6f6;
}

.list-group-item-light.list-group-item-action.active {
  color: var(--white);
  background-color: #818283;
  border-color: #818283;
}

.list-group-item-dark {
  color: #0a1123;
  background-color: #bdc1cb;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #0a1123;
  background-color: #afb4c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: var(--white);
  background-color: #0a1123;
  border-color: #0a1123;
}
// END List Group CSS

// Validations
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--success);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999999;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: var(--white);
  background-color: var(--success);
  border-radius: 5rem;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: var(--success);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: var(--success);
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--success);
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: var(--success);
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--success);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: var(--success);
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: var(--success);
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.invalid-feedback {
  display: block;
  width: 100%;
  font-size: 80%;
  color: var(--danger);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: var(--white);
  background-color: var(--danger);
  border-radius: 5rem;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--danger);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: var(--danger);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: var(--danger);
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: var(--danger);
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: var(--danger);
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #f27a9b;
  background-color: #f27a9b;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--danger);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: var(--danger);
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: var(--danger);
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.25);
}
// END Validations

// Button CSS
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: relative;
  color: var(--text-color-light);
  background-color: transparent;
  border: var(--btn-border);
  border-radius: var(--btn-border-radius);
  font-family: var(--font-family);
  font-weight: var(--body-font-weight);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  padding: 0.638rem 1rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--text-color-light);
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--blue-rgb), 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: inherit !important;
  cursor: not-allowed;
}

.btn.disabled,
.btn:disabled {
  color: gray;
  opacity: 0.5;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

a.disabled {
  cursor: not-allowed !important;
  pointer-events: inherit;
  opacity: 0.5;
}

.btn-default {
  color: var(--text-color-dark);
  background: var(--secondary-light);
  border-color: var(--secondary-light);
}

.btn-default:hover {
  color: var(--text-color-white);
  background: var(--secondary);
}

.btn-default.focus,
.btn-default:focus {
  color: var(--text-color-white);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--seondary-rgb), 0.5);
}

.btn-default.disabled,
.btn-default:disabled {
  color: var(--text-color-light);
  background-color: var(--default);
  border-color: var(--default);
}

.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active,
.show > .btn-default.dropdown-toggle {
  color: var(--text-color-white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-default:not(:disabled):not(.disabled).active:focus,
.btn-default:not(:disabled):not(.disabled):active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--seondary-rgb), 0.5);
}

.btn-primary {
  color: var(--white);
  background: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  color: var(--white);
  background: var(--secondary);
  border-color: var(--secondary);
}

.btn-primary.focus,
.btn-primary:focus {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-primary.focus-visible,
.btn-primary:focus-visible {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5);
}

.btn-secondary {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary);
  border-color: #54667a;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 152, 172, 0.5);
}

.btn-gradient {
  background: var(--gradient);
  color: var(--white) !important;
  border: 1px solid;

  &:hover {
    background: var(--gradient-hover);
    border: 1px solid;
  }

  &:disabled {
    color: lightgray !important;
  }
}

.btn-success {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:hover {
  color: var(--white);
  background-color: #00a387;
  border-color: #00967d;
}

.btn-success.focus,
.btn-success:focus {
  color: var(--white);
  background-color: #00a387;
  border-color: #00967d;
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: var(--white);
  background-color: #00967d;
  border-color: #008972;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: var(--white);
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-info:hover {
  color: var(--white);
  background-color: #00a6b5;
  border-color: #009aa8;
}

.btn-info.focus,
.btn-info:focus {
  color: var(--white);
  background-color: #00a6b5;
  border-color: #009aa8;
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 224, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: var(--white);
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: var(--white);
  background-color: #009aa8;
  border-color: #008e9b;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 224, 0.5);
}

.btn-warning {
  color: var(--text-color-dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:hover {
  color: var(--text-color-dark);
  background-color: #f2b876;
  border-color: #f0b26b;
}

.btn-warning.focus,
.btn-warning:focus {
  color: var(--text-color-dark);
  background-color: #f2b876;
  border-color: #f0b26b;
  box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: var(--text-color-dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: #f0b26b;
  border-color: #efac5f;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 177, 135, 0.5);
}

.btn-danger {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:hover {
  color: var(--white);
  background-color: #ea295e;
  border-color: #e81e55;
}

.btn-danger.focus,
.btn-danger:focus {
  color: var(--white);
  background-color: #ea295e;
  border-color: #e81e55;
  box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: var(--white);
  background-color: #e81e55;
  border-color: #e2174e;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 103, 140, 0.5);
}

.btn-light {
  color: var(--text-color-dark);
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

.btn-light:hover {
  color: var(--text-color-dark);
  background-color: #e0e5ef;
  border-color: #d7deeb;
}

.btn-light.focus,
.btn-light:focus {
  color: var(--text-color-dark);
  background-color: #e0e5ef;
  border-color: #d7deeb;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: var(--text-color-dark);
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: #d7deeb;
  border-color: #cfd7e7;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: var(--white);
  background-color: #132144;
  border-color: #132144;
}

.btn-dark:hover {
  color: var(--white);
  background-color: #0b1226;
  border-color: #080e1c;
}

.btn-dark.focus,
.btn-dark:focus {
  color: var(--white);
  background-color: #0b1226;
  border-color: #080e1c;
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 96, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: var(--white);
  background-color: #132144;
  border-color: #132144;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: var(--white);
  background-color: #080e1c;
  border-color: #050912;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 96, 0.5);
}

.btn-outline-default {
  color: var(--label);
  border-color: var(--fc-border-color);
}

.btn-outline-default:hover {
  color: var(--text-color-dark);
  background-color: var(--fc-background);
  border-color: var(--fc-border-color);
}

.btn-outline-default.focus,
.btn-outline-default:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-default.disabled,
.btn-outline-default:disabled {
  color: var(--label);
  background-color: transparent;
}

.btn-outline-default:not(:disabled):not(.disabled).active,
.btn-outline-default:not(:disabled):not(.disabled):active,
.show > .btn-outline-default.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: var(--fc-background);
  border-color: rgba(var(--primary-rgb), 0.4);
}

.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-primary {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);

  .n_icon {
    color: var(--white);
  }
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--blue-rgb), 0.5);
}

.btn-outline-primary.focus-visible,
.btn-outline-primary:focus-visible {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--blue-rgb), 0.5);
}

.btn-outline-secondary {
  color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:hover {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);

  .n_icon {
    color: var(--white);
  }
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--secondary);
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.btn-outline-success {
  color: var(--success);
  border-color: var(--success);
}

.btn-outline-success:hover {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: var(--success);
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-info {
  color: #00c9db;
  border-color: #00c9db;
}

.btn-outline-info:hover {
  color: var(--white);
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00c9db;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: var(--white);
  background-color: #00c9db;
  border-color: #00c9db;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.btn-outline-warning {
  color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning:hover {
  color: var(--text-color-dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: var(--warning);
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.btn-outline-danger {
  color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger:hover {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: var(--danger);
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.btn-outline-light {
  color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light:hover {
  color: var(--text-color-dark);
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f9fafc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.btn-outline-dark {
  color: #364655;
  border-color: #364655;
}

.btn-outline-dark:hover {
  color: var(--white);
  background-color: #364655;
  border-color: #364655;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #132144;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: var(--white);
  background-color: #132144;
  border-color: #132144;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.btn-link {
  font-weight: 400;
  color: var(--secondary);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary);
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: var(--caption-color);
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.638rem 1.25rem;
  font-size: 1rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.4375rem 0.625rem;
  font-size: 0.8125rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -0.0625rem;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.49219rem;
  padding-left: 0.49219rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.82031rem;
  padding-left: 0.82031rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -0.0625rem;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-wide {
  min-width: 7rem;
}

.btn-xs {
  font-size: 0.6875rem;
  font-weight: 400;
  padding: 0.5rem 0.75rem;
  border-radius: var(--fc-border-radius);
  line-height: 0.75rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

// END Button CSS

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

// Dropdown CSS
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 98;
  display: none;
  float: left;
  min-width: 8.4375rem;
  width: 100%;
  // padding: 1rem 0.5rem;
  // margin: 0.625rem 0 0;
  padding: 0;
  margin: 0;
  font-size: var(--body-font-size);
  color: var(--text-color-light);
  text-align: left;
  list-style: none;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: var(--fc-border-radius);
  box-shadow: 0 5px 20px 0px rgba(140, 152, 164, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: auto;
  left: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.625rem;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.625rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--light);
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  clear: both;
  font-weight: 400;
  color: var(--text-color-dark);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--border-radius);
  cursor: pointer;
  align-items: center;
  gap: 0.5em;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #121314;
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--text-color-dark);
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--caption-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-item-text {
  display: block;
  color: var(--text-color-dark);
  background: var(--secondary-light);
  padding: 0.5rem 1rem;
}

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
// END Dropdown CSS

// Custom Control CSS
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.53rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.2rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--white);
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 10px rgba(var(--blue-rgb), 0.1);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(var(--blue-rgb), 0.4);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: var(--caption-color);
}

.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #f8fafd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.11rem;
  left: -1.525rem;
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  pointer-events: none;
  content: "";
  background-color: var(--white);
  border: #687788 solid 0.095rem;
}

.custom-control-label::after {
  position: absolute;
  top: 0.138rem;
  left: -1.45rem;
  display: block;
  width: 1.02rem;
  height: 1.02rem;
  content: "";
  background: no-repeat 40%/70% 100%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 5rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(var(--blue-rgb), 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(var(--blue-rgb), 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(var(--blue-rgb), 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: 0.325rem;
  left: -2.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #d6dbeb;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--white);
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(var(--blue-rgb), 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 1.875rem 0.54688rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--text-color-dark);
  vertical-align: middle;
  background: var(--white)
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right 0.5rem center/1rem 1rem;
  border: 0.0625rem solid var(--light);
  border-radius: 5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: rgba(var(--blue-rgb), 0.4);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--primary-rgb), 0.25);
}

.custom-select:focus::-ms-value {
  color: var(--text-color-dark);
  background-color: var(--white);
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.875rem;
  background-image: none;
}

.custom-select:disabled {
  color: var(--caption-color);
  background-color: #f8fafd;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--text-color-dark);
}

.custom-select-sm {
  height: calc(1.5em + 1rem);
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 0.65625rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.875rem);
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 1.09375rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  margin: 0;
  opacity: 0;
}

.custom-control-input:active ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(var(--blue-rgb), 0.4);
  box-shadow: 0 0 10px rgba(var(--blue-rgb), 0.1);
}

.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #f8fafd;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.21875rem);
  padding: 0.54688rem 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--text-color-dark);
  background-color: var(--white);
  border: 0.0625rem solid var(--light);
  border-radius: 5rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 1.09375rem);
  padding: 0.54688rem 0.875rem;
  line-height: 1.6;
  color: var(--text-color-dark);
  content: "Browse";
  background-color: transparent;
  border-left: inherit;
  border-radius: 0 5rem 5rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 10px rgba(var(--blue-rgb), 0.1);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 10px rgba(var(--blue-rgb), 0.1);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px var(--white), 0 0 10px rgba(var(--blue-rgb), 0.1);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--light);
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--light);
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #eaf1ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: var(--light);
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: var(--light);
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: var(--fc-input-placeholder);
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: var(--fc-input-placeholder);
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: var(--fc-input-placeholder);
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
// END Custom Control CSS

// Nav Element CSS
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.719rem 0.703rem;
  color: var(--black);
  font-size: var(--body-font-size);
  font-weight: 600;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--primary);
  text-decoration: none;
}

.nav-link.disabled {
  color: var(--caption-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.0625rem solid var(--light);
}

.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}

.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  border-bottom-width: 0.0625rem;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link.disabled {
  color: var(--caption-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--primary);
  background-color: transparent;
  border-color: transparent transparent var(--primary);
  border-bottom-width: 0.25rem;
  margin-bottom: -0.1875rem;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 5rem;
  text-transform: capitalize;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--white);
  background-color: var(--primary);
}

.nav-fill .nav-item,
.nav-fill > .nav-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--white);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-xxl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }

  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.8125rem;
  padding-left: 0.8125rem;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  background-color: var(--white);
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: var(--primary);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: var(--primary);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text-color-light);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-hover);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: var(--primary);
}

.navbar-light .navbar-toggler {
  color: var(--text-color-light);
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: var(--text-color-light);
}

.navbar-light .navbar-text a {
  color: var(--primary);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: var(--primary);
}

.navbar-dark .navbar-brand {
  color: var(--white);
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: var(--white);
}

.navbar-dark .navbar-nav .nav-link {
  color: #96a2c1;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--white);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(var(--black-rgb), 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: var(--white);
}

.navbar-dark .navbar-toggler {
  color: #96a2c1;
  border-color: rgba(var(--black-rgb), 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%2396a2c1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #96a2c1;
}

.navbar-dark .navbar-text a {
  color: var(--white);
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: var(--white);
}
// END Nav Element CSS

// Card CSS
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border: 0.0625rem solid transparent;
  border-radius: var(--card-border-radius);
  // box-shadow: var(--card-box-shadow);
  padding: 1.25rem;
}

.card-vh-100 {
  min-height: calc(100vh - 180px);
  height: 100%;
}

.card-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  padding: 0;
}

.form-card,
.form-card .card-body {
  display: flex;
  flex-direction: column;
  gap: var(--gap-20);

  .form-block {
    margin-bottom: 0;
  }
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.3125rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: var(--card-border-radius);
}

.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--card-border-radius);
  border-bottom-left-radius: var(--card-border-radius);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.3125rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
// END Card CSS

// Accordion CSS
.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}
// END Accordion CSS

// Breadcrumb CSS
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-8);
  list-style: none;
  background-color: transparent;
  margin-bottom: 0;

  .breadcrumb-item {
    display: -ms-flexbox;
    display: flex;
    color: var(--primary);
    font-size: 0.8125rem;
    text-decoration: underline;
    &.active {
      color: var(--text-color-dark) !important;
      font-size: 1rem;
      font-weight: 700;
      text-decoration: none !important;
    }
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;

    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      content: ">";
    }
  }

  .breadcrumb-link {
    color: var(--caption-color);
    &:hover {
      color: var(--primary-hover);
    }
  }
  .breadcrumb-no-gutter {
    padding: 0;
  }
}
// END Breadcrumb CSS

// Pagination CSS
.pagination-wrapper {
  margin: auto;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: var(--fc-border-radius);
  position: relative;
  margin-bottom: 0;

  li:first-child {
    position: absolute;
    left: 0;
    margin: 0;
  }

  li:last-child {
    position: absolute;
    right: 0;
    margin: 0;
  }
}

.page-link {
  cursor: pointer;
  text-align: center;
  min-width: 1.75rem;
  font-size: 0.825rem;
  position: relative;
  display: block;
  padding: 0.5rem 0;
  margin-left: 0;
  line-height: 1;
  color: var(--text-color-light);
  background-color: var(--white);
  border: 0 solid var(--light);

  &:hover {
    z-index: 2;
    color: var(--primary);
    text-decoration: none;
    background-color: rgba(var(--blue-rgb), 0.1);
    border-color: rgba(var(--blue-rgb), 0.1);
  }

  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--blue-rgb), 0.25);
    // box-shadow: 0 0 0 0 transparent;
  }
}

.page-item {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.page-item .page-link {
  border-radius: 5px;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-radius: 5px;
  padding: 0.2rem 0.6rem;
}

.page-item:last-child .page-link {
  border-radius: 5px;
  padding: 0.2rem 0.6rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.page-item:not(.active) .page-link:hover {
  color: var(--primary);
}

.page-item.disabled .page-link {
  color: var(--caption-color) !important;
  pointer-events: auto;
  cursor: not-allowed;
  background-color: var(--white);
  border-color: var(--light);
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
// END Pagination CSS

// Badge CSS
.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25em 0.5em;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  white-space: normal;
  vertical-align: middle;
  border-radius: var(--badge-border-radius);
  transition: all 0.2s ease-in-out;
  cursor: default;
}

.badge .tio-clear {
  cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 50rem;
}

.badge-primary {
  color: var(--white);
  background-color: var(--primary);
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: var(--white);
  background-color: var(--secondary);
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--blue-rgb), 0.5);
}

.badge-secondary {
  color: var(--white);
  background-color: var(--secondary);
}

.badge-secondary-soft {
  color: var(--secondary);
  background: rgba(var(--primary-rgb), 0.15);
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: var(--white);
  background-color: var(--primary);
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(113, 134, 157, 0.5);
}

.badge-success {
  color: var(--white);
  background-color: var(--success);
}

a.badge-success:focus,
a.badge-success:hover {
  color: var(--white);
  background-color: #00967d;
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.badge-info {
  color: var(--white);
  background-color: #00c9db;
}

a.badge-info:focus,
a.badge-info:hover {
  color: var(--white);
  background-color: #009aa8;
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 219, 0.5);
}

.badge-warning {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: var(--text-color-dark);
  background-color: #f0b26b;
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 202, 153, 0.5);
}

.badge-danger {
  color: var(--white);
  background-color: var(--danger);
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: var(--white);
  background-color: #e81e55;
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 76, 120, 0.5);
}

.badge-light {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

a.badge-light:focus,
a.badge-light:hover {
  color: var(--text-color-dark);
  background-color: #d7deeb;
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5);
}

.badge-dark {
  color: var(--white);
  background-color: #132144;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: var(--white);
  background-color: #080e1c;
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(19, 33, 68, 0.5);
}

.badge-indigo {
  background: #EEF2FF;
  color: #4F46E5;
}
// END Badge CSS

// Alert CSS
.alert {
  position: relative;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: var(--alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3.59375rem;

  .btn-close {
    font-size: 0.825rem;
    padding: 1rem;
  }
}

.alert-soft-dark hr {
  border-color: rgba(189, 197, 209, 0.5);
}

.alert-primary {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);

  hr {
    border-top-color: #aecbff;
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-secondary {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);

  hr {
    border-top-color: #647991;
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-success {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);

  hr {
    border-top-color: #00b092;
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-info {
  color: var(--white);
  background-color: #00c9db;
  border-color: #00c9db;

  hr {
    border-top-color: #00b2c2;
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-warning {
  color: var(--text-color-dark);
  background-color: var(--warning);
  border-color: var(--warning);

  hr {
    border-top-color: #f3be82;
  }

  .alert-link {
    color: var(--text-color-dark);
  }
}

.alert-danger {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);

  hr {
    border-top-color: var(--danger);
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-light {
  color: var(--text-color-dark);
  background-color: #f9fafc;
  border-color: #f9fafc;

  hr {
    border-top-color: #e8ecf4;
  }

  .alert-link {
    color: var(--text-color-dark);
  }
}

.alert-dark {
  color: var(--white);
  background-color: #132144;
  border-color: #132144;

  hr {
    border-top-color: #0d1730;
  }

  .alert-link {
    color: var(--white);
  }
}

.alert-soft-primary {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);

  .alert-link {
    color: var(--primary);
    &:hover {
      color: #1366ff;
    }
  }
}

.alert-soft-secondary {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
  .alert-link {
    color: var(--secondary);
    &:hover {
      color: #60748b;
    }
  }
}

.alert-soft-success {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
  .alert-link {
    color: var(--success);
    &:hover {
      color: #00a589;
    }
  }
}

.alert-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  .alert-link {
    color: #00c9db;
    &:hover {
      color: #00a8b7;
    }
  }
}

.alert-soft-warning {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
  .alert-link {
    color: var(--warning);
    &:hover {
      color: #f2b978;
    }
  }
}

.alert-soft-danger {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
  .alert-link {
    color: var(--danger);
    &:hover {
      color: #ea2c60;
    }
  }
}

.alert-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  .alert-link {
    color: #f9fafc;
    &:hover {
      color: #e1e6f0;
    }
  }
}

.alert-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
  .alert-link {
    color: #132144;
    &:hover {
      color: #0b1328;
    }
  }
}
// END Alert CSS

// Progress Bar CSS
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress-indicator {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: var(--light);
  border-radius: var(--border-radius);
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--primary);
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(var(--black-rgb), 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(var(--black-rgb), 0.15) 50%,
    rgba(var(--black-rgb), 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
// END Progress Bar CSS

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.close {
  cursor: pointer;
  float: right;
  font-weight: 600;
  line-height: 1;
  color: var(--secondary);
  text-shadow: 0 1px 0 var(--white);
  opacity: 1;
}

.close:hover {
  color: var(--secondary);
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(var(--black-rgb), 0.85);
  background-clip: padding-box;
  border: 1px solid var(--light);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: var(--fc-border-radius);
}

.toast:not(:last-child) {
  margin-bottom: 1rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1rem;
  color: var(--caption-color);
  background-color: rgba(var(--black-rgb), 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.toast-body {
  padding: 1rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99999;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  padding-right: 0 !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 33, 68, 0.25);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
  z-index: 99999 !important;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: none;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
  opacity: 1;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: none;
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-footer-text:last-child {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 1;
  padding: 0 !important;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-bottom-color: var(--tooltip-bg) !important;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0d1730;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 2px;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0d1730;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--tooltip-bg) !important;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--tooltip-bg);
}

.tooltip-inner {
  min-width: 300px;
  padding: 0.5rem;
  color: var(--white);
  text-align: center;
  background-color: var(--tooltip-bg);
  border-radius: var(--border-radius);
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: var(--modal-border-radius);
  box-shadow: none;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.75rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: -0.5625rem;
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #132144;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.75rem 0;
}

.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #132144;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: -0.5625rem;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #132144;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0.0625rem solid #132144;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.75rem 0;
}

.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #132144;
}

.popover-header {
  padding: 0.75rem 1.09375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--white);
  background-color: #132144;
  border-bottom: 0.0625rem solid #0d1730;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.875rem 1.09375rem;
  color: #bdc5d1;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: var(--white);
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: var(--white);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
  filter: invert(36%) sepia(90%) saturate(1352%) hue-rotate(94deg)
    brightness(119%) contrast(119%) !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 5em !important;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;

  li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: var(--white);
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }

  button {
    background-color: var(--primary) !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--white);
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: var(--secondary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: var(--primary) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #00967d !important;
}

.bg-info {
  background-color: #00c9db !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #009aa8 !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f0b26b !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e81e55 !important;
}

.bg-light {
  background-color: #f9fafc !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d7deeb !important;
}

.bg-dark {
  background-color: #132144 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #080e1c !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-gray {
  background-color: var(--gray) !important;
}

.bg-blue {
  background-color: var(--blue) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.0625rem solid var(--light) !important;
}

.border-top {
  border-top: 0.0625rem solid var(--light) !important;
}

.border-right {
  border-right: 0.0625rem solid var(--light) !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid var(--light) !important;
}

.border-left {
  border-left: 0.0625rem solid var(--light) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

.border-success {
  border-color: var(--success) !important;
}

.border-info {
  border-color: var(--info) !important;
}

.border-warning {
  border-color: var(--warning) !important;
}

.border-danger {
  border-color: var(--danger) !important;
}

.border-light {
  border-color: var(--light) !important;
}

.border-dark {
  border-color: var(--dark) !important;
}

.border-white {
  border-color: var(--white) !important;
}
.border-gray {
  border-color: var(--gray-600) !important;
}

@media (min-width: 992px) {
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: var(--fc-border-radius) !important;
}

.rounded-top {
  border-top-left-radius: 5rem !important;
  border-top-right-radius: 5rem !important;
}

.rounded-right {
  border-top-right-radius: 5rem !important;
  border-bottom-right-radius: 5rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}

.rounded-left {
  border-top-left-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}

.rounded-lg {
  border-radius: 0.75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
}

.shadow-sm {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}

.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: var(--white) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-border-primary {
  border-bottom: 2px solid var(--primary) !important;
}

.text-border-secondary {
  border-bottom: 2px solid var(--secondary) !important;
}

.text-border-success {
  border-bottom: 2px solid var(--success) !important;
}

.text-border-warning {
  border-bottom: 2px solid var(--warning) !important;
}

.text-border-danger {
  border-bottom: 2px solid var(--danger) !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary-hover) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: var(--primary) !important;
}

.text-success {
  color: var(--success) !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #007d67 !important;
}

.text-info {
  color: var(--info) !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #00838f !important;
}

.text-warning {
  color: var(--warning) !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: var(--warning) !important;
}

.text-danger {
  color: var(--danger) !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: var(--danger) !important;
}

.text-light {
  color: var(--light) !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #c6d0e3 !important;
}

.text-dark {
  color: var(--text-color-dark) !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: var(--dark) !important;
}

.text-body {
  color: var(--text-color-light) !important;
}

.text-muted {
  color: var(--caption-color) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(var(--black-rgb), 0.5) !important;
}

.text-hide {
  font: 0/0;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::-moz-selection {
  color: var(--white);
  background-color: var(--primary);
}

.bg-primary ::-moz-selection {
  color: var(--primary);
  background-color: var(--white);
}

.bg-primary ::selection {
  color: var(--primary);
  background-color: var(--white);
}

:focus,
a:focus,
button:focus {
  outline: 0;
}

.btn.focus,
.btn:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not([disabled]):not(.disabled).active,
.btn:not([disabled]):not(.disabled):active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

.navbar-nav-wrap-content-left .navbar-nav > .nav-item:not(:last-child),
.navbar-nav-wrap-content-right .navbar-nav > .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar:not(.navbar-vertical).nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar .nav-link {
  color: #132144;
}

.navbar .nav-link:hover {
  color: var(--primary-hover);
}

.navbar .nav-link.active {
  color: var(--primary);
}

.navbar .active > .nav-link,
.navbar .nav-link.active,
.navbar .nav-link.show,
.navbar .show > .nav-link {
  color: var(--primary);
}

.navbar .nav-pills .show > .nav-link {
  color: var(--white);
}

.navbar:not(.navbar-vertical) .nav-link-toggle::after,
.navbar:not(.navbar-vertical) .nav-link-toggle[data-toggle="collapse"]::after {
  padding-left: 1.5rem;
}

.navbar .navbar-dropdown-menu .dropdown-menu:not(.navbar-dropdown-sub-menu) {
  position: absolute;
}

.navbar .dropdown-menu.hs-mega-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .hs-sub-menu.dropdown-menu .dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .dropdown-item-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .dropdown-item-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-left: auto;
  transition: 0.3s;
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.navbar-fixed ~ .main {
  padding-top: 3.75rem;
}

@media (min-width: 576px) {
  .navbar-fixed-sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .navbar-fixed-sm ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .navbar-fixed-md ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 992px) {
  .navbar-fixed-lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .navbar-fixed-lg ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 1200px) {
  .navbar-fixed-xl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .navbar-fixed-xl ~ .main {
    padding-top: 3.75rem;
  }
}

@media (min-width: 1400px) {
  .navbar-fixed-xxl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .navbar-fixed-xxl ~ .main {
    padding-top: 3.75rem;
  }
}

.navbar-flush .navbar {
  padding-right: 1rem;
}

.navbar-bordered {
  border-width: 0 0 0.0625rem 0;
  border-style: solid;
  border-color: var(--light);
}

.navbar-bordered.navbar-vertical {
  border-width: 0 0.0625rem 0 0;
}

.navbar-shadow {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
}

.navbar-brand-logo {
  width: 100%;
  min-width: auto;
  max-width: auto;
  height: 40px;
}

.navbar-brand-logo-short {
  width: 100%;
  min-width: 2rem;
  max-width: 2rem;
}

.navbar-brand-wrapper {
  position: relative;
  overflow-x: hidden;
}

.navbar-toggler:hover {
  background-color: #f0f4fb;
}

.navbar-toggler.btn-white {
  border-color: var(--light);
}

.navbar-toggler {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 5rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-toggler.btn-white.active,
.navbar-toggler.btn-white:active,
.navbar-toggler.btn-white:focus,
.navbar-toggler.btn-white:hover {
  color: var(--primary-hover);
  background-color: var(--white);
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.navbar-toggler .navbar-toggle-default {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler .navbar-toggle-toggled {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggle-default {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggle-toggled {
  display: -ms-flexbox;
  display: flex;
}

.navbar-collapse .navbar-nav {
  margin-top: 0;
}

.navbar-nav-mega-menu {
  width: 100%;
}

.navbar-nav-mega-menu .dropdown-item,
.navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  display: block;
}

.navbar-input-group {
  min-width: 32rem;
}

.navbar-input-group input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type="search"]::-webkit-search-cancel-button,
.navbar-input-group input[type="search"]::-webkit-search-decoration,
.navbar-input-group input[type="search"]::-webkit-search-results-button,
.navbar-input-group input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.navbar-dropdown-menu {
  position: absolute !important;
  margin-top: 1.25rem;
}

.navbar-dropdown-menu:not(.navbar-dropdown-account) {
  padding: 0;
}

.navbar-dropdown-account-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: var(--text-color-dark);
  background-color: transparent;
  padding: 0.168946rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius);
  transition: 0.3s;
}

.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: rgba(var(--blue-rgb), 0.1);
}

.navbar-dropdown-account-body {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.navbar-card-list-group {
  margin: 0.5rem;
}

.navbar-card-list-group .list-group-item {
  padding: 0.5rem;
}

.navbar-expand .navbar-vertical.navbar-collapse {
  display: block !important;
}

.navbar-expand .navbar-vertical.navbar-collapse .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-expand .navbar-vertical.navbar-vertical-fixed {
  height: 100%;
}

.navbar-expand.navbar {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand.navbar-height {
  height: 3.75rem;
}

.navbar-expand .navbar-brand-wrapper-width {
  width: 100%;
  max-width: 16.25rem;
}

.navbar-expand .navbar-nav .navbar-nav-link {
  line-height: 2.3125rem;
}

.navbar-expand .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
  padding-left: 0;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  margin-top: 0;
}

.navbar-expand.navbar:not(.navbar-vertical) .nav-indicator-icon {
  display: none;
}

.navbar-expand .navbar-nav-mega-menu-body {
  padding: 1rem 0.5rem;
  margin-left: 0;
  margin-right: 0;
}

.navbar-expand .dropdown-menu {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -1rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical.navbar-collapse {
    display: block !important;
  }

  .navbar-expand-sm .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-expand-sm .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }

  .navbar-expand-sm.navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-sm.navbar-height {
    height: 3.75rem;
  }

  .navbar-expand-sm .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }

  .navbar-expand-sm .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }

  .navbar-expand-sm .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }

  .navbar-expand-sm.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }

  .navbar-expand-sm .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-sm .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }

  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical.navbar-collapse {
    display: block !important;
  }

  .navbar-expand-md .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-expand-md .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }

  .navbar-expand-md.navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-md.navbar-height {
    height: 3.75rem;
  }

  .navbar-expand-md .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }

  .navbar-expand-md .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }

  .navbar-expand-md .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }

  .navbar-expand-md.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }

  .navbar-expand-md .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-md .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }

  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical.navbar-collapse {
    display: block !important;
  }

  .navbar-expand-lg .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-expand-lg .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }

  .navbar-expand-lg.navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-lg.navbar-height {
    height: 3.75rem;
  }

  .navbar-expand-lg .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }

  .navbar-expand-lg .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }

  .navbar-expand-lg .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }

  .navbar-expand-lg.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }

  .navbar-expand-lg .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }

  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical.navbar-collapse {
    display: block !important;
  }

  .navbar-expand-xl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-expand-xl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }

  .navbar-expand-xl.navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-xl.navbar-height {
    height: 3.75rem;
  }

  .navbar-expand-xl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }

  .navbar-expand-xl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }

  .navbar-expand-xl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }

  .navbar-expand-xl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }

  .navbar-expand-xl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-xl .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }

  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical.navbar-collapse {
    display: block !important;
  }

  .navbar-expand-xxl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-expand-xxl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }

  .navbar-expand-xxl.navbar {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-xxl.navbar-height {
    height: 3.75rem;
  }

  .navbar-expand-xxl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }

  .navbar-expand-xxl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }

  .navbar-expand-xxl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }

  .navbar-expand-xxl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }

  .navbar-expand-xxl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-xxl .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }

  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-sm .navbar-collapse {
    background-color: var(--white);
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-expand-sm .navbar-vertical .navbar-vertical-footer {
    display: none;
  }

  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }

  .navbar-expand-sm .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-expand-sm .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-expand-sm .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-expand-sm .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-sm .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-sm.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }

  .navbar-expand-sm .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-sm.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: var(--white);
  }

  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }

  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: var(--white);
  }

  .navbar-expand-sm .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-sm .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
  }

  .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-sm .dropdown-item-toggle::after,
  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-sm
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-sm .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  .navbar-expand-sm .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }

  .navbar-expand-sm .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-sm .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-sm .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-sm
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-sm
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }

  .navbar-expand-sm .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }

  .navbar-expand-sm .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }

  .navbar-expand-sm
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .navbar-expand-sm ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-md .navbar-collapse {
    background-color: var(--white);
  }

  .navbar-expand-md .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-expand-md .navbar-vertical .navbar-vertical-footer {
    display: none;
  }

  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }

  .navbar-expand-md .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-expand-md .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-expand-md .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-expand-md .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-md .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-md.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }

  .navbar-expand-md .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-md.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: var(--white);
  }

  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }

  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: var(--white);
  }

  .navbar-expand-md .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-md .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
  }

  .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-md .dropdown-item-toggle::after,
  .navbar-expand-md .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-md .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-md
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-md .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  .navbar-expand-md .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }

  .navbar-expand-md .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-md .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-md .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-md
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-md
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }

  .navbar-expand-md .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }

  .navbar-expand-md .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }

  .navbar-expand-md
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .navbar-expand-md ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-lg .navbar-collapse {
    background-color: var(--white);
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-expand-lg .navbar-vertical .navbar-vertical-footer {
    display: none;
  }

  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }

  .navbar-expand-lg .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-expand-lg .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-expand-lg .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-expand-lg .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }

  .navbar-expand-lg .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-lg.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: var(--white);
  }

  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }

  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: var(--white);
  }

  .navbar-expand-lg .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-lg .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }

  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-lg .dropdown-item-toggle::after,
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-lg
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-lg .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  .navbar-expand-lg .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }

  .navbar-expand-lg .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-lg .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-lg .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-lg
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-lg
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }

  .navbar-expand-lg .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }

  .navbar-expand-lg .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }

  .navbar-expand-lg
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .navbar-expand-lg ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-xl .navbar-collapse {
    background-color: var(--white);
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-expand-xl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }

  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }

  .navbar-expand-xl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-expand-xl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-expand-xl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-expand-xl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }

  .navbar-expand-xl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: var(--white);
  }

  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }

  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: var(--white);
  }

  .navbar-expand-xl .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-xl .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }

  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-xl .dropdown-item-toggle::after,
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-xl
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-xl .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  .navbar-expand-xl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }

  .navbar-expand-xl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-xl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xl
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xl
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }

  .navbar-expand-xl .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }

  .navbar-expand-xl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }

  .navbar-expand-xl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .navbar-expand-xl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-xxl .navbar-collapse {
    background-color: var(--white);
  }

  .navbar-expand-xxl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-expand-xxl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }

  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }

  .navbar-expand-xxl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-expand-xxl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-expand-xxl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-expand-xxl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xxl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xxl.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }

  .navbar-expand-xxl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xxl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: var(--white);
  }

  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }

  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: var(--white);
  }

  .navbar-expand-xxl .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-xxl .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-xxl .hs-mega-menu {
    max-width: 100% !important;
  }

  .navbar-expand-xxl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xxl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-xxl .dropdown-item-toggle::after,
  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-xxl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .navbar-expand-xxl
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar-expand-xxl
    .hs-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  .navbar-expand-xxl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }

  .navbar-expand-xxl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xxl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand-xxl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xxl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xxl
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }

  .navbar-expand-xxl
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }

  .navbar-expand-xxl .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }

  .navbar-expand-xxl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }

  .navbar-expand-xxl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .navbar-expand-xxl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}

.navbar-expand.navbar-spacer-y {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand .navbar-collapse .navbar-nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-expand .navbar-vertical .navbar-vertical-footer {
  display: none;
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand
  .navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu) {
  background-color: transparent;
}

.navbar-expand .navbar-body {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}

.navbar-expand .navbar-body::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-expand .navbar-body::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.navbar-expand .navbar-body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item,
.navbar-expand .navbar-dark .navbar-nav .nav-link,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item,
.navbar-expand.navbar-dark .navbar-nav .nav-link {
  color: #96a2c1;
}

.navbar-expand .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
.navbar-expand.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
  color: var(--white);
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider {
  border-color: #192b58;
}

.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover {
  color: var(--white);
}

.navbar-expand .navbar-nav-mega-menu .dropdown-item,
.navbar-expand .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
}

.navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-expand .dropdown-item-toggle::after,
.navbar-expand .navbar-dropdown-submenu-item-invoker::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand
  .navbar-dropdown-submenu-item.hs-active
  .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}

.navbar-expand .dropdown-menu.navbar-dropdown-sub-menu {
  box-shadow: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
}

.navbar-expand .navbar-nav-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav-item .navbar-nav-item {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav-item .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand .navbar-nav-item > .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand .hs-has-sub-menu .navbar-nav-item .hs-sub-menu .dropdown-item {
  padding-left: 1.75rem;
}

.navbar-expand
  .hs-has-sub-menu
  .hs-sub-menu
  .hs-has-sub-menu:not(.navbar-nav-item)
  .hs-sub-menu {
  padding-left: 1.75rem;
}

.navbar-expand .hs-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.8125rem;
}

.navbar-expand .navbar.navbar-vertical-detached {
  position: relative;
  width: auto;
}

.navbar-expand .navbar.navbar-vertical-detached .navbar-vertical-container {
  height: auto;
  min-height: auto;
  max-height: none;
}

.navbar-expand ~ .sidebar-detached-content {
  margin-left: 0;
}

.navbar.navbar-container {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

@media (max-width: 575.98px) {
  [class*="navbar-expand"].navbar {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap-content-left {
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap-content-right {
  margin-left: auto;
}

.navbar-expand .navbar-nav-wrap-content-left {
  -ms-flex-order: 2;
  order: 2;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2rem;
}

.navbar-expand .navbar-nav-wrap-content-right {
  margin-left: auto;
  -ms-flex-order: 4;
  order: 4;
}

.navbar-expand .navbar-nav-wrap-toggle {
  -ms-flex-order: 4;
  order: 4;
  text-align: right;
}

.navbar-expand .navbar-nav-wrap-navbar {
  -ms-flex-order: 3;
  order: 3;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }

  .navbar-expand-sm .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }

  .navbar-expand-sm .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }

  .navbar-expand-sm .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }

  .navbar-expand-md .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }

  .navbar-expand-md .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }

  .navbar-expand-md .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10rem;
  }

  .navbar-expand-lg .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }

  .navbar-expand-lg .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }

  .navbar-expand-lg .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }

  .navbar-expand-xl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }

  .navbar-expand-xl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }

  .navbar-expand-xl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }

  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }

  .navbar-expand-xxl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }

  .navbar-expand-xxl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}

.navbar-expand .navbar-nav-wrap {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-vertical.navbar {
  display: block;
  padding: 0;
  height: calc(100% - 4rem);
}

.navbar-vertical.navbar.collapse:not(.show) {
  display: none;
}

.navbar-vertical.navbar .nav-item {
  white-space: nowrap;
}

.navbar-vertical.navbar .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.navbar-vertical.navbar .nav-subtitle-replacer {
  display: none;
  color: var(--text-color-light);
  text-align: center;
  font-size: 1.3125rem;
  padding: 0.5rem 0;
}

.navbar-vertical-container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.navbar-vertical-fixed .navbar-vertical-container {
  max-height: 100%;
}

.navbar-vertical-content {
  height: calc(100% - 6.5rem);
  overflow-y: auto;
  overflow: hidden;
}

.navbar-vertical .nav-link {
  color: #132144;
}

.navbar-vertical .nav-link.disabled {
  color: var(--caption-color);
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.75rem;
}

.navbar-vertical .nav-sub {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
}

.navbar-vertical .nav-sub .nav-link {
  font-size: 0.875rem;
}

.navbar-vertical .nav-sub.nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-vertical .nav-subtitle {
  padding: 0.75rem 0.8125rem;
}

.navbar-vertical .navbar-nav.nav-tabs {
  border-bottom-width: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-item:not(:last-child) {
  margin: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
  border-width: 0 0 0 0.25rem;
  margin-bottom: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .nav-link.active {
  border-bottom-width: 0;
  border-radius: 0;
}

.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.navbar-vertical .navbar-nav.nav-tabs .active .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  border-left-color: var(--primary);
}

.navbar-vertical
  .navbar-nav.nav-tabs
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  margin-right: 0;
}

.navbar-vertical .nav-link-toggle::after,
.navbar-vertical .nav-link-toggle[data-toggle="collapse"]::after {
  transition: 0.3s ease;
}

.navbar-vertical .nav-link-toggle[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical .navbar-vertical-has-menu {
  position: relative;
}

.navbar-vertical .active .nav-indicator-icon,
.navbar-vertical .nav-link:hover .nav-indicator-icon,
.navbar-vertical .show > .nav-link > .nav-indicator-icon {
  color: var(--primary);
}

.navbar-vertical .nav-compact,
.navbar-vertical .nav-compact-icon {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-vertical .nav-compact .nav-compact-title,
.navbar-vertical .nav-compact-icon .nav-compact-title {
  max-width: 8.5rem;
}

.navbar-vertical .nav-compact .nav-link,
.navbar-vertical .nav-compact-icon .nav-link {
  display: block;
}

.navbar-vertical .nav-compact .nav-sub .nav-link,
.navbar-vertical .nav-compact-icon .nav-sub .nav-link {
  display: -ms-flexbox;
  display: flex;
}

.navbar-vertical-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16.25rem;
  margin-left: -16.25rem;
}

.navbar-vertical-fixed {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 16.25rem;
}

.navbar-vertical-fixed .navbar-vertical-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.navbar-vertical-fixed .navbar-vertical-footer-offset {
  height: 100%;
  padding-bottom: 4.25rem;
}

.navbar-vertical:not([class*="container"]) .navbar-nav.navbar-nav-lg .nav-link {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav-link {
  padding: 0.375rem 1.75rem;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 3.25rem;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 4.25rem;
}

.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle-replacer,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-title {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-vertical:not([class*="container"])
  .navbar-vertical-without-icons
  .nav
  .nav-link {
  padding-left: 3.25rem;
}

.navbar-vertical:not([class*="container"])
  .navbar-vertical-without-icons
  .nav
  .nav
  .nav-link {
  padding-left: 4.25rem;
}

.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact,
.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact-icon {
  margin-left: 0;
}

.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact .nav-link,
.navbar-vertical:not([class*="container"])
  .navbar-nav.nav-compact-icon
  .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-vertical-footer {
  border-top: 0.0625rem solid var(--light);
  padding: 2rem 1.5rem;
}

.navbar-vertical-footer-list {
  list-style: none;
  margin-bottom: 0;
}

.navbar-vertical-footer-list .navbar-vertical-footer-list-item {
  position: relative;
  margin: 0 0.5rem;
}

.navbar-vertical-footer-dropdown {
  margin-bottom: 1.5rem;
}

.navbar-vertical-detached {
  top: auto;
  left: auto;
  width: 16.25rem;
  margin-left: 0;
  bottom: auto;
}

.navbar-vertical-detached .navbar-vertical-footer {
  position: static;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-link,
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-subtitle {
  padding-right: 0;
  padding-left: 0;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav-link {
  padding-right: 0;
  padding-left: 2rem;
}

.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav .nav-link {
  padding-right: 0;
  padding-left: 3rem;
}

.navbar-vertical-aside-transition-on .footer,
.navbar-vertical-aside-transition-on .main,
.navbar-vertical-aside-transition-on .navbar,
.navbar-vertical-aside-transition-on .navbar-vertical-aside {
  transition: 0.2s ease-in-out;
}

.navbar-vertical-aside-transition-on
  .navbar-vertical-aside
  .navbar-vertical-footer {
  opacity: 0;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
  padding-left: 0;
}

.navbar-fixed ~ .main .navbar-vertical-aside {
  top: 0;
}

[class*="navbar-vertical-aside-show"]
  .navbar.splitted-content-navbar
  .navbar-brand-wrapper {
  display: block;
}

[class*="navbar-vertical-aside-show"]
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
}

[class*="navbar-vertical-aside-show"] .navbar-nav-wrap-content-left {
  padding-left: 0;
}

.navbar-vertical-aside-mini-mode-hidden-elements {
  -ms-flex: 1;
  flex: 1;
}

.navbar-vertical-aside .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 3.75rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-vertical-aside .navbar-brand-logo {
  display: block;
}

.navbar-vertical-aside .navbar-brand-logo-mini {
  width: 1.9125rem;
  display: none;
}

.navbar-vertical-content::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-vertical-content::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.nav-footer-item {
  display: none;
}

.nav-footer-item:last-child {
  margin-bottom: 2rem;
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  position: fixed;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer {
  position: static;
  border-top: 0.0625rem solid #e7eaf3;
  padding: 1.25rem 1.85rem;
  margin: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item {
  position: static;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item
  .hs-unfold {
  position: static;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-offset {
  padding-bottom: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-list-item {
  margin: 0.5rem 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown {
  top: 0;
  left: calc(100% + 1rem);
  margin: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown.hs-unfold-reverse-y {
  top: auto;
  bottom: 0;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}

.navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: 0;
  margin-top: 60px;
}

.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: -16.25rem;
}

.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.splitted-content-navbar.navbar-vertical-aside-initialized {
  margin-left: -21.5rem;
}

.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
  margin-left: -16.25rem;
}

.navbar-vertical-aside-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  opacity: 1;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle {
  position: relative;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle::before {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #bdc5d1;
  opacity: 0;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity 0.3s;
}

.navbar-vertical-aside
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle.active::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle:hover::before {
  background-color: var(--primary);
}

.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical
  .nav-tabs
  .navbar-vertical-aside-has-menu
  > .active.nav-link {
  border-color: transparent;
}

.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical-aside-has-menu.show
  > .nav-sub {
  display: block;
}

.navbar-vertical-aside-has-menu {
  position: relative;
}

.navbar-vertical-aside-has-menu > .nav {
  display: none;
}

.navbar-vertical-aside-has-menu > .nav-link-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-left: auto;
  margin-right: 0.1875rem;
  transition: 0.3s ease;
}

.navbar-vertical-aside-has-menu.show > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-container {
  overflow-y: scroll;
  overflow-y: overlay;
}

.navbar-vertical-aside-mini-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}

.navbar-vertical-aside-compact-mode .navbar-vertical-aside {
  width: 9rem !important;
}

.navbar-vertical-aside-compact-mode .navbar-vertical .navbar-brand {
  margin-right: 0;
}

.navbar-vertical-aside-compact-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}

.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: var(--white);
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: var(--border-radius);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  .nav-icon {
  color: var(--primary-hover);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: -0.5rem;
  opacity: 1;
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: var(--white);
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: var(--border-radius);
}

@media (max-width: 575.98px) {
  .navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    min-width: 100%;
  }
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}

.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav {
  margin-left: 0;
}

.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: 0.75rem;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container,
.navbar-vertical-aside-mini-mode .navbar-vertical-container {
  position: static;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-has-menu,
.navbar-vertical-aside-mini-mode .navbar-vertical-aside-has-menu {
  position: static;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: inline-block;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav-link {
  padding-left: 3.5rem;
  padding-right: 1.75rem;
}

.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav
  .nav-link {
  padding-left: 2.25rem;
}

.navbar-vertical-aside .splitted-content-mini {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}

.navbar-vertical-aside-mobile-overlay,
.navbar-vertical-aside-sub-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}

.navbar-vertical-aside-mobile-overlay {
  background-color: rgba(19, 33, 68, 0.25);
}

.navbar-vertical-aside-mobile-overlay-transparent {
  background-color: transparent;
}

.has-navbar-vertical-aside.navbar-vertical-aside-sub-menu-opened
  .navbar-vertical-aside-sub-menu-overlay,
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  z-index: 99;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.navbar-vertical.navbar-expand {
  -ms-flex-flow: column;
  flex-flow: column;
}

.navbar-vertical.navbar-expand .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-vertical-aside-show .main {
  padding-left: 16.25rem;
}

.navbar-vertical-aside-show .footer {
  margin-left: 16.25rem;
}

.navbar-vertical-aside-show .navbar:not(.navbar-vertical) {
  margin-left: 16.25rem;
}

.navbar-vertical-aside-show .splitted-content-navbar {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .main {
  padding-left: 9rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .footer {
  margin-left: 9rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 9rem;
}

.navbar-vertical-aside-show.splitted-content .main {
  padding-left: 21.5rem;
}

.navbar-vertical-aside-show.splitted-content .footer {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show.splitted-content .navbar:not(.navbar-vertical) {
  margin-left: 21.5rem;
}

.navbar-vertical-aside-show .navbar-vertical-aside {
  margin-left: 0;
}

.navbar-vertical-aside-show
  .navbar:not(.navbar-vertical-aside)
  .navbar-brand-wrapper {
  display: none;
}

.navbar-vertical-aside-show
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .main {
  padding-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .footer {
  margin-left: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside {
  width: 5.25rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-navbar {
  width: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .footer,
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical),
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-main {
  padding-left: 21.5rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical) {
  margin-left: 2rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo-mini {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo {
  display: none;
}

.navbar-vertical-aside-show:not(.navbar-vertical-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  display: none;
}

.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align,
.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}

.navbar-vertical-aside-show .navbar-vertical-aside-toggle {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-content {
  overflow-y: scroll;
  overflow-y: overlay;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav
  .nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle {
  display: none;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: var(--white);
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: var(--border-radius);
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}

.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  width: 2rem;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .nav-footer-item {
  display: block;
}

.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-footer {
  display: none;
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-vertical-aside-show-sm .main {
    padding-left: 16.25rem;
  }

  .navbar-vertical-aside-show-sm .footer {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-sm .splitted-content-navbar {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-sm.splitted-content .main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-sm.splitted-content .footer {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-sm.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-sm .navbar-vertical-aside {
    margin-left: 0;
  }

  .navbar-vertical-aside-show-sm
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-sm
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }

  .navbar-vertical-aside-show-sm:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }

  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }

  .navbar-vertical-aside-show-sm .navbar-vertical-aside-toggle {
    display: none;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: var(--white);
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: var(--border-radius);
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-vertical-aside-show-md .main {
    padding-left: 16.25rem;
  }

  .navbar-vertical-aside-show-md .footer {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-md .splitted-content-navbar {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-md.splitted-content .main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-md.splitted-content .footer {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-md.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-md .navbar-vertical-aside {
    margin-left: 0;
  }

  .navbar-vertical-aside-show-md
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-md
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }

  .navbar-vertical-aside-show-md:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }

  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }

  .navbar-vertical-aside-show-md .navbar-vertical-aside-toggle {
    display: none;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: var(--white);
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: var(--border-radius);
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-vertical-aside-show-lg .main {
    padding-left: 16.25rem;
  }

  .navbar-vertical-aside-show-lg .footer {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-lg .splitted-content-navbar {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-lg.splitted-content .main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-lg.splitted-content .footer {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-lg.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-lg .navbar-vertical-aside {
    margin-left: 0;
  }

  .navbar-vertical-aside-show-lg
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-lg
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }

  .navbar-vertical-aside-show-lg:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }

  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }

  .navbar-vertical-aside-show-lg .navbar-vertical-aside-toggle {
    display: none;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: var(--white);
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: var(--border-radius);
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-vertical-aside-show-xl .main {
    padding-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xl .footer {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xl .splitted-content-navbar {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-xl.splitted-content .main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xl.splitted-content .footer {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xl .navbar-vertical-aside {
    margin-left: 0;
  }

  .navbar-vertical-aside-show-xl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-xl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }

  .navbar-vertical-aside-show-xl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }

  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }

  .navbar-vertical-aside-show-xl .navbar-vertical-aside-toggle {
    display: none;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: var(--white);
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: var(--border-radius);
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-vertical-aside-show-xxl .main {
    padding-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xxl .footer {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xxl .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }

  .navbar-vertical-aside-show-xxl .splitted-content-navbar {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }

  .navbar-vertical-aside-show-xxl.splitted-content .main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl.splitted-content .footer {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl .navbar-vertical-aside {
    margin-left: 0;
  }

  .navbar-vertical-aside-show-xxl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-xxl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }

  .navbar-vertical-aside-show-xxl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }

  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }

  .navbar-vertical-aside-show-xxl .navbar-vertical-aside-toggle {
    display: none;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: var(--white);
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: var(--border-radius);
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-vertical-aside-show-sm .navbar-brand-logo-mini {
    display: none;
  }

  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }

  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-vertical-aside-show-sm.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-vertical-aside-show-md .navbar-brand-logo-mini {
    display: none;
  }

  .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }

  .navbar-vertical-aside-show-md.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-vertical-aside-show-md.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-vertical-aside-show-lg .navbar-brand-logo-mini {
    display: none;
  }

  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }

  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-vertical-aside-show-lg.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-vertical-aside-show-xl .navbar-brand-logo-mini {
    display: none;
  }

  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }

  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-vertical-aside-show-xl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

@media (max-width: 1399.98px) {
  .navbar-vertical-aside-show-xxl .navbar-brand-logo-mini {
    display: none;
  }

  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }

  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}

.navbar-vertical-aside-show .navbar-brand-logo-mini {
  display: none;
}

.navbar-vertical-aside-show.has-navbar-vertical-aside .navbar-height {
  height: 3.75rem;
}

.navbar-vertical-aside-show.has-navbar-vertical-aside
  .navbar-nav-wrap-content-left {
  display: -ms-flexbox;
  display: flex;
}

.navbar-vertical-aside-show.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside {
  margin-left: -100%;
}

.has-navbar-vertical-aside .navbar-vertical-aside-toggle-short-align {
  display: block;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-short-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}

.has-navbar-vertical-aside .navbar-vertical-aside-toggle-full-align {
  display: none;
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-full-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}

.splitted-content-navbar .navbar-vertical-aside-toggle-short-align {
  display: block;
}

.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}

.splitted-content-navbar .navbar-vertical-aside-toggle-full-align {
  display: none;
}

.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.navbar-light {
  background-color: #f9fafc;
}

.navbar-light .navbar-collapse {
  background-color: transparent;
}

.navbar-light .navbar-brand-wrapper {
  background-color: #f9fafc;
}

.navbar-vertical .nav-link-toggle[aria-expanded="false"]::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.search-fullwidth {
  position: fixed;
  top: 0;
  left: 0 !important;
  width: 100%;
  z-index: 101;
}

.search-fullwidth .form-control {
  height: 3.75rem;
}

// Avatar CSS
.avatar-primary .avatar-initials {
  color: var(--white);
  background-color: var(--primary);
}

.avatar-status-primary {
  color: var(--white);
  background-color: var(--primary);
}

.avatar-secondary .avatar-initials {
  color: var(--white);
  background-color: var(--secondary);
}

.avatar-status-secondary {
  color: var(--white);
  background-color: var(--secondary);
}

.avatar-success .avatar-initials {
  color: var(--white);
  background-color: var(--success);
}

.avatar-status-success {
  color: var(--white);
  background-color: var(--success);
}

.avatar-info .avatar-initials {
  color: var(--white);
  background-color: #00c9db;
}

.avatar-status-info {
  color: var(--white);
  background-color: #00c9db;
}

.avatar-warning .avatar-initials {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

.avatar-status-warning {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

.avatar-danger .avatar-initials {
  color: var(--white);
  background-color: var(--danger);
}

.avatar-status-danger {
  color: var(--white);
  background-color: var(--danger);
}

.avatar-light .avatar-initials {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

.avatar-status-light {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

.avatar-dark .avatar-initials {
  color: var(--white);
  background-color: #132144;
}

.avatar-status-dark {
  color: var(--white);
  background-color: #132144;
}

.avatar-soft-primary .avatar-initials {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.avatar-status-soft-primary {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.avatar-soft-secondary .avatar-initials {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-status-soft-secondary {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-soft-success .avatar-initials {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-status-soft-success {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-soft-info .avatar-initials {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.avatar-status-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.avatar-soft-warning .avatar-initials {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-status-soft-warning {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-soft-danger .avatar-initials {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-status-soft-danger {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-soft-light .avatar-initials {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.avatar-status-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.avatar-soft-dark .avatar-initials {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.avatar-status-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.avatar:not(img) {
  background: var(--gray-100);
  padding: 0.1rem;
}

.avatar-img {
  color: var(--text-color-light);
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  pointer-events: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: var(--border-radius);
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle .avatar-img,
.avatar-circle .avatar-initials {
  border-radius: 50%;
}

.avatar-border-lg {
  border: 0.1875rem solid var(--white);
}

.avatar-border-dashed {
  border: 0.0625rem dashed var(--light);
}

.avatar-border-dashed .avatar-initials {
  color: var(--caption-color);
  background-color: var(--white);
  transition: 0.3s;
}

.avatar-border-dashed:hover .avatar-initials {
  color: var(--primary);
}

.avatar-group {
  position: relative;
  z-index: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar,
.avatar-group .avatar-sm,
.avatar-group .avatar-xs {
  padding: 0.125rem 0.125rem;
}

.avatar-group .avatar-lg,
.avatar-group .avatar-xl,
.avatar-group .avatar-xxl {
  padding: 0.25rem 0.25rem;
}

.avatar-group.avatar-circle .avatar {
  border-radius: 50%;
}

.avatar-group.avatar-circle .avatar .avatar-img,
.avatar-group.avatar-circle .avatar .avatar-initials {
  border-radius: 50%;
}

.avatar-group .avatar {
  width: 2.625rem;
  height: 2.625rem;
}

.avatar-group .avatar .avatar-initials {
  font-size: 1.09375rem;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group-xs .avatar {
  width: 1.53125rem;
  height: 1.53125rem;
}

.avatar-group-xs .avatar .avatar-initials {
  font-size: var(--body-font-size);
}

.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}

.avatar-group-sm .avatar {
  width: 2.1875rem;
  height: 2.1875rem;
}

.avatar-group-sm .avatar .avatar-initials {
  font-size: 0.98438rem;
}

.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}

.avatar-group-lg .avatar {
  width: 3.9375rem;
  height: 3.9375rem;
}

.avatar-group-lg .avatar .avatar-initials {
  font-size: 1.53125rem;
}

.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xl .avatar {
  width: 4.92188rem;
  height: 4.92188rem;
}

.avatar-group-xl .avatar .avatar-initials {
  font-size: 1.96875rem;
}

.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xxl .avatar {
  width: 7.875rem;
  height: 7.875rem;
}

.avatar-group-xxl .avatar .avatar-initials {
  font-size: 2.625rem;
}

.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}

.avatar.avatar-4by3 {
  width: 3.5rem;
}

.avatar-xss.avatar-4by3 {
  width: 1.33333rem;
}

.avatar-xs.avatar-4by3 {
  width: 2.04167rem;
}

.avatar-sm.avatar-4by3 {
  width: 2.91667rem;
}

.avatar-lg.avatar-4by3 {
  width: 5.25rem;
}

.avatar-xl.avatar-4by3 {
  width: 6.5625rem;
}

.avatar-xxl.avatar-4by3 {
  width: 10.5rem;
}

.avatar.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.28437rem;
  right: -0.28437rem;
}

.avatar-xss {
  width: 1rem;
  height: 1rem;
}

.avatar-xss .avatar-initials {
  font-size: 0.875rem;
}

.avatar-xs {
  width: 1.53125rem;
  height: 1.53125rem;
  .avatar-img {
    font-size: 0.8rem;
  }
}

.avatar-xs .avatar-initials {
  font-size: 0.875rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.10254rem;
  right: -0.10254rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.28437rem;
  right: -0.28437rem;
}

.avatar-sm {
  width: 2.1875rem;
  height: 2.1875rem;
}

.avatar-sm .avatar-initials {
  font-size: 0.98438rem;
}

.avatar-sm.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}

.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.08203rem;
  right: -0.08203rem;
}

.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.35547rem;
  right: -0.35547rem;
}

.avatar-lg {
  width: 3.9375rem;
  height: 3.9375rem;
  .avatar-img {
    font-size: 1.5rem;
  }
}

.avatar-lg .avatar-initials {
  font-size: 1.53125rem;
}

.avatar-lg.avatar-circle .avatar-status {
  bottom: -2.1px;
  right: -2.1px;
}

.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.08203rem;
  right: 0.08203rem;
}

.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.17773rem;
  right: -0.17773rem;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .avatar-initials {
  font-size: 1.96875rem;
}

.avatar-xl.avatar-circle .avatar-status {
  bottom: 2.625px;
  right: 2.625px;
}

.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.20508rem;
  right: 0.20508rem;
}

.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.11849rem;
  right: 0.11849rem;
}

.avatar-xxl {
  width: 7.875rem;
  height: 7.875rem;
}

.avatar-xxl .avatar-initials {
  font-size: 2.625rem;
}

.avatar-xxl.avatar-circle .avatar-status {
  bottom: 6px;
  right: 6px;
}

.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 0.82031rem;
  right: 0.82031rem;
}

.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 0.35547rem;
  right: 0.35547rem;
}

.avatar-status {
  position: absolute;
  bottom: -8.4px;
  right: -8.4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid var(--white);
  width: 21px;
  height: 21px;
  line-height: 1;
  font-size: 0.71094rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -4.2px;
  right: -4.2px;
  width: 0.82031rem;
  height: 0.82031rem;
  font-size: 0.4375rem;
}

.avatar-lg-status {
  width: 1.42188rem;
  height: 1.42188rem;
  font-size: 0.65625rem;
}

.avatar-uploader {
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.2s;
  margin-bottom: 0;
}

.avatar-uploader:hover {
  border-color: var(--primary);
}

.avatar-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  border-radius: 0.4375rem;
  border: 1px solid var(--secondary-light);
  background: var(--white);

  .img-icon {
    display: flex;
    font-size: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    opacity: 0.3;
  }
}

.avatar:hover .delete {
  display: inline-flex;
}

.avatar .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4em;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: rgb(244 67 54 / 55%);
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, 0.25);
  border-radius: 50%;
  transition: 0.2s;
}

.avatar-uploader-trigger {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
}

.avatar-uploader-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--text-color-light);
  background-color: var(--white);
  border-radius: 50%;
  transition: 0.2s;
}

.avatar-uploader:hover .avatar-uploader-icon {
  color: var(--white);
  background-color: var(--primary);
}

.avatar-lg .avatar-uploader-icon {
  font-size: 0.75rem;
  width: 1.3125rem;
  height: 1.3125rem;
}

.avatar-xl .avatar-uploader-icon {
  font-size: 0.8125rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.avatar-xxl .avatar-uploader-icon {
  width: 2.1875rem;
  height: 2.1875rem;
}

// END Avatar CSS

// BADGE CSS
.badge-soft-primary {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

a.badge-soft-primary:focus,
a.badge-soft-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.badge-soft-secondary {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
}

a.badge-soft-secondary:focus,
a.badge-soft-secondary:hover {
  color: var(--white);
  background-color: var(--secondary);
}

.badge-soft-success {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

a.badge-soft-success:focus,
a.badge-soft-success:hover {
  color: var(--white);
  background-color: var(--success);
}

.badge-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

a.badge-soft-info:focus,
a.badge-soft-info:hover {
  color: var(--white);
  background-color: #00c9db;
}

.badge-soft-warning {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
}

a.badge-soft-warning:focus,
a.badge-soft-warning:hover {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

.badge-soft-danger {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
}

a.badge-soft-danger:focus,
a.badge-soft-danger:hover {
  color: var(--white);
  background-color: var(--danger);
}

.badge-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

a.badge-soft-light:focus,
a.badge-soft-light:hover {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

.badge-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

a.badge-soft-dark:focus,
a.badge-soft-dark:hover {
  color: var(--white);
  background-color: #132144;
}

// Blockquote CSS
.blockquote {
  border-left: 0.1875rem solid var(--light);
  padding-left: 0.75rem;
}

.blockquote-footer {
  display: block;
  color: var(--caption-color);
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.blockquote-sm {
  font-size: 0.8125rem;
  padding-left: 0.5rem;
}
// END Blockquote CSS

.btn-dashed-outline {
  border-style: dashed;
}

.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.btn-white {
  background-color: var(--white);
  border-color: var(--light);
}

.btn-white.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-white.active,
.btn-white.focus,
.btn-white:active,
.btn-white:focus,
.btn-white:hover {
  color: var(--primary-hover);
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.btn-white.active.dropdown-toggle::after,
.btn-white.focus.dropdown-toggle::after,
.btn-white:active.dropdown-toggle::after,
.btn-white:focus.dropdown-toggle::after,
.btn-white:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-white.disabled,
.btn-white:disabled {
  color: var(--caption-color);
  background-color: rgba(231, 234, 243, 0.5);
}

.btn-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-primary.active.dropdown-toggle::after,
.btn-outline-primary.focus.dropdown-toggle::after,
.btn-outline-primary:active.dropdown-toggle::after,
.btn-outline-primary:focus.dropdown-toggle::after,
.btn-outline-primary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-secondary.active.dropdown-toggle::after,
.btn-outline-secondary.focus.dropdown-toggle::after,
.btn-outline-secondary:active.dropdown-toggle::after,
.btn-outline-secondary:focus.dropdown-toggle::after,
.btn-outline-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-success.active.dropdown-toggle::after,
.btn-outline-success.focus.dropdown-toggle::after,
.btn-outline-success:active.dropdown-toggle::after,
.btn-outline-success:focus.dropdown-toggle::after,
.btn-outline-success:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-info.active.dropdown-toggle::after,
.btn-outline-info.focus.dropdown-toggle::after,
.btn-outline-info:active.dropdown-toggle::after,
.btn-outline-info:focus.dropdown-toggle::after,
.btn-outline-info:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-warning.active.dropdown-toggle::after,
.btn-outline-warning.focus.dropdown-toggle::after,
.btn-outline-warning:active.dropdown-toggle::after,
.btn-outline-warning:focus.dropdown-toggle::after,
.btn-outline-warning:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-danger.active.dropdown-toggle::after,
.btn-outline-danger.focus.dropdown-toggle::after,
.btn-outline-danger:active.dropdown-toggle::after,
.btn-outline-danger:focus.dropdown-toggle::after,
.btn-outline-danger:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-light.active.dropdown-toggle::after,
.btn-outline-light.focus.dropdown-toggle::after,
.btn-outline-light:active.dropdown-toggle::after,
.btn-outline-light:focus.dropdown-toggle::after,
.btn-outline-light:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-outline-dark.active.dropdown-toggle::after,
.btn-outline-dark.focus.dropdown-toggle::after,
.btn-outline-dark:active.dropdown-toggle::after,
.btn-outline-dark:focus.dropdown-toggle::after,
.btn-outline-dark:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-primary {
  color: var(--primary);
  background-color: transparent;
}

.btn-ghost-primary.active,
.btn-ghost-primary.focus,
.btn-ghost-primary:active,
.btn-ghost-primary:focus,
.btn-ghost-primary:hover {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.btn-ghost-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-primary.btn-no-focus[href].focus,
.btn-ghost-primary.btn-no-focus[href]:focus {
  color: var(--primary);
  background-color: unset;
}

.btn-ghost-primary.btn-no-focus[href].focus:hover,
.btn-ghost-primary.btn-no-focus[href]:focus:hover {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.btn-ghost-secondary {
  color: var(--secondary);
  background-color: transparent;
}

.btn-ghost-secondary.active,
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: var(--secondary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.btn-ghost-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-secondary.btn-no-focus[href].focus,
.btn-ghost-secondary.btn-no-focus[href]:focus {
  color: var(--secondary);
  background-color: unset;
}

.btn-ghost-secondary.btn-no-focus[href].focus:hover,
.btn-ghost-secondary.btn-no-focus[href]:focus:hover {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.btn-ghost-success {
  color: var(--success);
  background-color: transparent;
}

.btn-ghost-success.active,
.btn-ghost-success.focus,
.btn-ghost-success:active,
.btn-ghost-success:focus,
.btn-ghost-success:hover {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-success.btn-no-focus[href].focus,
.btn-ghost-success.btn-no-focus[href]:focus {
  color: var(--success);
  background-color: unset;
}

.btn-ghost-success.btn-no-focus[href].focus:hover,
.btn-ghost-success.btn-no-focus[href]:focus:hover {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-info {
  color: #00c9db;
  background-color: transparent;
}

.btn-ghost-info.active,
.btn-ghost-info.focus,
.btn-ghost-info:active,
.btn-ghost-info:focus,
.btn-ghost-info:hover {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.btn-ghost-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-info.btn-no-focus[href].focus,
.btn-ghost-info.btn-no-focus[href]:focus {
  color: #00c9db;
  background-color: unset;
}

.btn-ghost-info.btn-no-focus[href].focus:hover,
.btn-ghost-info.btn-no-focus[href]:focus:hover {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}

.btn-ghost-warning {
  color: var(--warning);
  background-color: transparent;
}

.btn-ghost-warning.active,
.btn-ghost-warning.focus,
.btn-ghost-warning:active,
.btn-ghost-warning:focus,
.btn-ghost-warning:hover {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-warning.btn-no-focus[href].focus,
.btn-ghost-warning.btn-no-focus[href]:focus {
  color: var(--warning);
  background-color: unset;
}

.btn-ghost-warning.btn-no-focus[href].focus:hover,
.btn-ghost-warning.btn-no-focus[href]:focus:hover {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-danger {
  color: var(--danger);
  background-color: transparent;
}

.btn-ghost-danger.active,
.btn-ghost-danger.focus,
.btn-ghost-danger:active,
.btn-ghost-danger:focus,
.btn-ghost-danger:hover {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-danger.btn-no-focus[href].focus,
.btn-ghost-danger.btn-no-focus[href]:focus {
  color: var(--danger);
  background-color: unset;
}

.btn-ghost-danger.btn-no-focus[href].focus:hover,
.btn-ghost-danger.btn-no-focus[href]:focus:hover {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-light {
  color: #f9fafc;
  background-color: transparent;
}

.btn-ghost-light.active,
.btn-ghost-light.focus,
.btn-ghost-light:active,
.btn-ghost-light:focus,
.btn-ghost-light:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat center center/1.5rem 2.5rem;
}

.btn-ghost-light.btn-no-focus[href].focus,
.btn-ghost-light.btn-no-focus[href]:focus {
  color: #f9fafc;
  background-color: unset;
}

.btn-ghost-light.btn-no-focus[href].focus:hover,
.btn-ghost-light.btn-no-focus[href]:focus:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-dark {
  color: #132144;
  background-color: transparent;
}

.btn-ghost-dark.active,
.btn-ghost-dark.focus,
.btn-ghost-dark:active,
.btn-ghost-dark:focus,
.btn-ghost-dark:hover {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.btn-ghost-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-ghost-dark.btn-no-focus[href].focus,
.btn-ghost-dark.btn-no-focus[href]:focus {
  color: #132144;
  background-color: unset;
}

.btn-ghost-dark.btn-no-focus[href].focus:hover,
.btn-ghost-dark.btn-no-focus[href]:focus:hover {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}

.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: var(--primary);
}

.btn-ghost-secondary.focus.dropdown-toggle::after,
.btn-ghost-secondary:active.dropdown-toggle::after,
.btn-ghost-secondary:focus.dropdown-toggle::after,
.btn-ghost-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.btn-soft-default {
  color: var(--secondary);
  background-color: var(--white);
  border-color: transparent;
}

.btn-soft-default.active,
.btn-soft-default:active,
.btn-soft-default:focus,
.btn-soft-default:hover {
  color: var(--white);
  background-color: var(--secondary);
  box-shadow: 0 4px 11px rgba(var(--blue-rgb), 0.1);
}

.btn-soft-default.disabled,
.btn-soft-default:disabled {
  color: var(--secondary);
  background-color: rgba(var(--blue-rgb), 0.1);
  box-shadow: none;
}

.btn-soft-default:not(:disabled):not(.disabled).active,
.btn-soft-default:not(:disabled):not(.disabled):active,
.show > .btn-soft-default.dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
}

.btn-soft-primary {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
  border-color: transparent;
}

.btn-soft-primary.active,
.btn-soft-primary:active,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
  color: var(--white);
  background-color: var(--primary);
  box-shadow: 0 4px 11px rgba(var(--blue-rgb), 0.1);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
  box-shadow: none;
}

.btn-soft-primary:not(:disabled):not(.disabled).active,
.btn-soft-primary:not(:disabled):not(.disabled):active,
.show > .btn-soft-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary);
}

.btn-soft-secondary {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}

.btn-soft-secondary.active,
.btn-soft-secondary:active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
  color: var(--white);
  background-color: var(--secondary);
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.1);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: var(--secondary);
  background-color: rgba(113, 134, 157, 0.1);
  box-shadow: none;
}

.btn-soft-secondary:not(:disabled):not(.disabled).active,
.btn-soft-secondary:not(:disabled):not(.disabled):active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: var(--white);
  background-color: var(--secondary);
}

.btn-soft-success {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success.active,
.btn-soft-success:active,
.btn-soft-success:focus,
.btn-soft-success:hover {
  color: var(--white);
  background-color: var(--success);
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.1);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: var(--success);
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}

.btn-soft-success:not(:disabled):not(.disabled).active,
.btn-soft-success:not(:disabled):not(.disabled):active,
.show > .btn-soft-success.dropdown-toggle {
  color: var(--white);
  background-color: var(--success);
}

.btn-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  border-color: transparent;
}

.btn-soft-info.active,
.btn-soft-info:active,
.btn-soft-info:focus,
.btn-soft-info:hover {
  color: var(--white);
  background-color: #00c9db;
  box-shadow: 0 4px 11px rgba(0, 201, 219, 0.1);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  box-shadow: none;
}

.btn-soft-info:not(:disabled):not(.disabled).active,
.btn-soft-info:not(:disabled):not(.disabled):active,
.show > .btn-soft-info.dropdown-toggle {
  color: var(--white);
  background-color: #00c9db;
}

.btn-soft-warning {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}

.btn-soft-warning.active,
.btn-soft-warning:active,
.btn-soft-warning:focus,
.btn-soft-warning:hover {
  color: var(--text-color-dark);
  background-color: var(--warning);
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.1);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: var(--warning);
  background-color: rgba(245, 202, 153, 0.1);
  box-shadow: none;
}

.btn-soft-warning:not(:disabled):not(.disabled).active,
.btn-soft-warning:not(:disabled):not(.disabled):active,
.show > .btn-soft-warning.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

.btn-soft-danger {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}

.btn-soft-danger.active,
.btn-soft-danger:active,
.btn-soft-danger:focus,
.btn-soft-danger:hover {
  color: var(--white);
  background-color: var(--danger);
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.1);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: var(--danger);
  background-color: rgba(237, 76, 120, 0.1);
  box-shadow: none;
}

.btn-soft-danger:not(:disabled):not(.disabled).active,
.btn-soft-danger:not(:disabled):not(.disabled):active,
.show > .btn-soft-danger.dropdown-toggle {
  color: var(--white);
  background-color: var(--danger);
}

.btn-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  border-color: transparent;
}

.btn-soft-light.active,
.btn-soft-light:active,
.btn-soft-light:focus,
.btn-soft-light:hover {
  color: var(--text-color-dark);
  background-color: #f9fafc;
  box-shadow: 0 4px 11px rgba(249, 250, 252, 0.1);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  box-shadow: none;
}

.btn-soft-light:not(:disabled):not(.disabled).active,
.btn-soft-light:not(:disabled):not(.disabled):active,
.show > .btn-soft-light.dropdown-toggle {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

.btn-soft-dark {
  color: #364655;
  background-color: #e7eaf3;
  border-color: transparent;
}

.btn-soft-dark.active,
.btn-soft-dark:active,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
  color: var(--white);
  background-color: #364655;
  box-shadow: 0 4px 11px rgba(19, 33, 68, 0.1);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #364655;
  background-color: rgba(19, 33, 68, 0.1);
  box-shadow: none;
}

.btn-soft-dark:not(:disabled):not(.disabled).active,
.btn-soft-dark:not(:disabled):not(.disabled):active,
.show > .btn-soft-dark.dropdown-toggle {
  color: var(--white);
  background-color: #364655;
}

.btn-group-segment {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8fafd;
  border-radius: var(--fc-border-radius);
  padding: 0.25rem 0.25rem;
}

.btn-group-segment .btn {
  color: var(--text-color-light);
}

.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}

.btn-group-segment .btn:hover {
  color: var(--primary);
}

.btn-group-segment .btn.active {
  color: var(--text-color-dark);
  background-color: var(--white);
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

.btn-group-segment .btn:not(:disabled):not(.disabled).active,
.btn-group-segment .btn:not(:disabled):not(.disabled):active {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

.btn-group-segment > .btn-group:not(:first-child) > .btn,
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:not(:first-child),
.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: var(--fc-border-radius);
}

.btn-group-segment.btn-group-pills {
  border-radius: 6.1875rem;
}

.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn,
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills
  > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 6.1875rem;
}

.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.btn-group-sm > .btn-icon.btn,
.btn-icon.btn-sm {
  font-size: 0.875rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.btn-group-lg > .btn-icon.btn,
.btn-icon.btn-lg {
  font-size: 1rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

.btn-status {
  position: absolute;
  top: -0.4375rem;
  right: -0.4375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.71094rem;
  font-weight: 600;
  width: 21px;
  height: 21px;
  background-color: #bdc5d1;
  border: 0.125rem solid var(--white);
  padding: 0;
  border-radius: 50%;
}

.btn.rounded-circle .btn-status {
  top: -0.29167rem;
  right: -0.29167rem;
}

.btn-xs-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.53594rem;
  width: 10px;
  height: 10px;
  border-width: 0.1rem;
}

.btn.rounded-circle .btn-xs-status {
  top: -0.07813rem;
  right: -0.07813rem;
}

.btn-sm-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.65625rem;
  width: 12px;
  height: 12px;
}

.btn.rounded-circle .btn-sm-status {
  top: -0.15625rem;
  right: -0.15625rem;
}

.btn-lg-status {
  top: -0.6125rem;
  right: -0.6125rem;
  font-size: 0.875rem;
  width: 24px;
  height: 24px;
}

.btn.rounded-circle .btn-lg-status {
  top: -0.49rem;
  right: -0.49rem;
}

.btn-status-primary {
  color: var(--white);
  background-color: var(--primary);
}

.btn-status-secondary {
  color: var(--white);
  background-color: var(--secondary);
}

.btn-status-success {
  color: var(--white);
  background-color: var(--success);
}

.btn-status-info {
  color: var(--white);
  background-color: #00c9db;
}

.btn-status-warning {
  color: var(--text-color-dark);
  background-color: var(--warning);
}

.btn-status-danger {
  color: var(--white);
  background-color: var(--danger);
}

.btn-status-light {
  color: var(--text-color-dark);
  background-color: #f9fafc;
}

.btn-status-dark {
  color: var(--white);
  background-color: #132144;
}

label.btn {
  cursor: pointer !important;
  margin-bottom: 0;
}

.btn.dropdown-toggle::after {
  transition: all 0.2s ease-in-out;
}

.btn-pill {
  border-radius: 6.1875rem;
}

.btn-toggle-default {
  display: inline-block;
}

.btn-toggle-toggled {
  display: none;
}

.btn.toggled .btn-toggle-default,
.btn[aria-expanded="true"] .btn-toggle-default {
  display: none;
}

.btn.toggled .btn-toggle-toggled,
.btn[aria-expanded="true"] .btn-toggle-toggled {
  display: inline-block;
}

.content-space {
  padding: 2rem;
}

@media (max-width: 575.98px) {
  .content-space {
    padding: 1rem;
  }
}

.navbar-expand .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
  padding-left: 16.25rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}

.footer-offset {
  margin-bottom: 3.5rem;
}

@media (max-width: 575.98px) {
  .container-fluid,
  .container-xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.kanban-board {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.kanban-board::-webkit-scrollbar {
  height: 0.6125rem;
}

.kanban-board::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.kanban-board .kanban-board-row {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.kanban-board .kanban-board-row > [class*="col"] {
  max-width: 350px;
}

.sidebar-detached-content {
  margin-left: 18.25rem;
}

.splitted-content-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.navbar-vertical.splitted-content-navbar {
  width: 21.5rem;
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-content {
  height: 100%;
}

.navbar-vertical.splitted-content-navbar .navbar-nav {
  margin-left: 5.25rem;
}

.navbar-vertical.splitted-content-navbar .navbar-vertical-footer {
  margin-left: 5.25rem;
}

.splitted-content-bordered:not(:last-child) {
  border-right: 0.0625rem solid var(--light);
}

.splitted-content-bordered:not(:first-child) {
  border-left: 0.0625rem solid var(--light);
}

.navbar-vertical-aside
  ~ .splitted-content-small.splitted-content-bordered:not(:last-child) {
  margin-left: -0.0625rem;
}

.splitted-content-mini,
.splitted-content-small {
  background-color: var(--white);
}

.splitted-content-mini {
  width: 5.25rem;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.splitted-content-mini .navbar-brand {
  margin-right: 0;
}

.splitted-content-fluid,
.splitted-content-small {
  min-height: 100vh;
}

.splitted-content-small {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20rem;
  max-width: 100%;
}

.splitted-content-fluid {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.header ~ .main .splitted-content-fluid,
.header ~ .main .splitted-content-mini,
.header ~ .main .splitted-content-small {
  min-height: calc(100vh - 3.75rem);
}

.splitted-content-mini.hs-unfold-content-initialized,
.splitted-content-small.hs-unfold-content-initialized {
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1200px) {
  .splitted-content-toggle {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .splitted-content-mini.hs-unfold-hidden,
  .splitted-content-small.hs-unfold-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }

  .splitted-content-small {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }

  .splitted-content-mini-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
  }
}

.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}

.page-header-title {
  margin-bottom: 0.25rem;
}

.page-header-text {
  margin-bottom: 0.25rem;
}

.page-header-tabs {
  margin-bottom: -1.75rem;
  border-bottom-width: 0;
}

.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.page-header-light {
  border-bottom-color: rgba(var(--black-rgb), 0.1);
}

.page-header-light .page-header-title {
  color: var(--white);
}

.page-header-light .page-header-text {
  color: rgba(var(--black-rgb), 0.7);
}

.card-bg {
  background-color: var(--card-background);
}

.card[href]:hover .card-header-title,
.card[href]:hover .card-title {
  color: var(--primary-hover);
}

.card-title {
  display: block;
  color: var(--text-color-dark);
  margin-bottom: 0;
}

.card-title-light {
  display: block;
  color: var(--text-color-white);
  margin-bottom: 0;
}

.card-subtitle {
  display: block;
  color: var(--caption-color);
  font-weight: 600;
  letter-spacing: 0.03125rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card-text {
  display: block;
  color: var(--text-color-light);
}

.card-header {
  -ms-display: -ms-block;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0 1rem 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0.0625rem solid var(--gray-300);
  color: var(--text-color-dark);
}

.card-header:first-child {
  border-radius: 0.6875rem 0.6875rem 0 0;
}

.card-header-tabs {
  margin-right: -0.65625rem;
  margin-bottom: -1.3125rem;
  margin-left: -0.65625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.65625rem;
  margin-left: -0.65625rem;
}

.card-header-title {
  margin-bottom: 0;
}

.card-footer {
  display: block;
  padding: 1rem 0 0 0;
  background-color: transparent;
  border-top: 0.0625rem solid var(--gray-300);
}

.card-footer:last-child {
  border-radius: 0 0 0.6875rem 0.6875rem;
}

.card-sm > .card-footer,
.card-sm > .card-header {
  padding-right: 1rem;
  padding-left: 1rem;
}

.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1rem 1rem;
}

.card-sm .card-table td,
.card-sm .card-table th {
  padding-right: 1rem;
  padding-left: 1rem;
}

.card-lg > .card-footer,
.card-lg > .card-header {
  padding-right: 2rem;
  padding-left: 2.15rem;
}

.card-lg .card-table td,
.card-lg .card-table th {
  padding-right: 2rem;
  padding-left: 2rem;
}

.card-header-borderless .card-header {
  border-width: 0;
  padding-bottom: 0;
}

.card-body-height {
  height: 21.25rem;
  overflow: hidden;
  overflow-y: auto;
}

.card-body-height::-webkit-scrollbar {
  width: 0.6125rem;
}

.card-body-height::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.card-body-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-body-stretched {
  padding: 0 1.3125rem 1.3125rem 1.3125rem;
}

.card-border {
  border:1px solid var(--secondary-light) !important;
}

.card-dashed {
  border-width: 0;
}

.card-dashed:hover {
  border-color: rgba(var(--blue-rgb), 0.7);
}

.card-dashed:hover .card-dashed-body {
  color: var(--primary-hover);
}

.card-dashed-body {
  color: var(--caption-color);
  border: 0.0625rem dashed rgba(214, 219, 235, 0.7);
  border-radius: 0.75rem;
  transition: 0.3s;
}

.card-border-left {
  border-left: 0.125rem solid var(--light);
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.card-hover-shadow {
  transition: 0.3s;
}

.card-hover-shadow:hover {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
}

.card-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: inherit;
}

.card-btn-toggle {
  display: inline-block;
  color: var(--primary);
}

.card-btn-toggle-default {
  display: none;
}

.card-btn-toggle-active {
  display: block;
}

.card-btn.collapsed .card-btn-toggle-default {
  display: block;
}

.card-btn.collapsed .card-btn-toggle-active {
  display: none;
}

.card-pinned {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.card-table {
  margin-bottom: 0;
}

.card-table td,
.card-table th {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.card-table .table-thead-bordered {
  margin-top: -0.0625rem;
}

.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .card-dropdown-filter-centered {
    left: -8rem;
    min-width: 18rem !important;
  }
}

.card-progress-wrap {
  overflow: hidden;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.card-progress {
  height: 0.25rem;
}

.card-progress:first-child .progress-bar {
  border-top-left-radius: 0.75rem;
}

.card-progress:first-child .progress-bar[aria-valuenow="100"] {
  border-top-right-radius: 0.75rem;
}

.card-nav-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-nav-vertical .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.card-nav-vertical.card-nav {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.card-navbar-nav {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.card-navbar-nav .nav-icon {
  margin-right: 0.5rem;
}

.card > .profile-cover,
.card > .profile-cover .profile-cover-img,
.card > .profile-cover .profile-cover-img-wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header ~ .profile-cover,
.card > .card-header ~ .profile-cover .profile-cover-img,
.card > .card-header ~ .profile-cover .profile-cover-img-wrapper {
  border-radius: 0;
}

.card .jvectormap-custom .jvectormap-container {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.column-divider {
  position: relative;
}

.column-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 0.0625rem solid var(--light);
  content: "";
}

@media (min-width: 576px) {
  .column-divider-sm {
    position: relative;
  }

  .column-divider-sm::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--light);
    content: "";
  }
}

@media (min-width: 768px) {
  .column-divider-md {
    position: relative;
  }

  .column-divider-md::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--light);
    content: "";
  }
}

@media (min-width: 992px) {
  .column-divider-lg {
    position: relative;
  }

  .column-divider-lg::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--light);
    content: "";
  }
}

@media (min-width: 1200px) {
  .column-divider-xl {
    position: relative;
  }

  .column-divider-xl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--light);
    content: "";
  }
}

@media (min-width: 1400px) {
  .column-divider-xxl {
    position: relative;
  }

  .column-divider-xxl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid var(--light);
    content: "";
  }
}

.divider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.divider::after,
.divider::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid var(--light);
  content: "";
  margin-top: 0.0625rem;
}

.divider::before {
  margin-right: 1.5rem;
}

.divider::after {
  margin-left: 1.5rem;
}

.link {
  text-decoration: underline;
  font-size: 0.8125rem;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 30rem;
  height: 100%;
  background-color: var(--white);
}

.sidebar-bordered {
  border-left: 0.0625rem solid var(--light);
}

.sidebar-box-shadow {
  box-shadow: -5px 0 12px rgba(103, 119, 136, 0.1);
}

.sidebar-left {
  left: 0;
  right: auto;
}

.sidebar-left.sidebar-bordered {
  border-left: none;
  border-right: 0.0625rem solid var(--light);
}

.sidebar-left.sidebar-box-shadow {
  box-shadow: 5px 0 12px rgba(103, 119, 136, 0.1);
}

.sidebar-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 2;
}

.sidebar-body {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.sidebar-scrollbar {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.sidebar-scrollbar::-webkit-scrollbar {
  width: 0.6125rem;
}

.sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.sidebar-scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.sidebar-card {
  border-radius: 0;
}

.sidebar-card .card-header {
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  padding: 1rem;
  background: var(--white);
}

.sidebar-card .card-footer {
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  padding: 1rem;
  text-align: right;
  background: var(--white);
}

.dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
}

.dropdown-item:active {
  color: var(--text-color-dark);
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item.active:not(:focus):not(:active) {
  color: var(--text-color-dark);
  background-color: rgba(189, 197, 209, 0.3);
}

.dropdown-item[href]:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 0.8125rem;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  margin-bottom: 0;
  color: var(--caption-color);
  white-space: nowrap;
}

.dropdown-menu-sm {
  padding: 0.5rem;
}

.dropdown-menu-sm .dropdown-divider {
  margin: 0.25rem;
}

.dropdown-menu-lg .dropdown-header,
.dropdown-menu-lg .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.dropdown-menu-lg .dropdown-item {
  font-size: 0.875rem;
}

.dropdown-menu-lg .dropdown-item-icon {
  font-size: 1.125rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

.dropdown-item-icon {
  display: inline-block;
  font-size: 1rem;
  width: 1rem;
  color: var(--text-color-light);
}

.dropdown-toggle {
  position: relative;
  white-space: nowrap;
  font-weight: normal;
  background: var(--secondary-light);
  border-color: var(--secondary-light);
  color: var(--text-color-dark);
  font-size: 0.875rem;
}

.dropdown-toggle[aria-expanded="true"] i {
  color: inherit;
}

.dropdown-toggle:hover i {
  color: var(--white) !important;
}

.dropdown-toggle:focus i {
  color: var(--white) !important;
}

.dropdown-toggle::after {
  display: inline;
  width: 0rem;
  height: 0rem;
  content: "";
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  border: none;
}

.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dropdown-toggle-collapse::after {
  transition: 0.3s;
}

.dropdown-toggle-collapse[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
}

.footer:not([class*="container"]) {
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (max-width: 575.98px) {
  .footer:not([class*="container"]) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.footer-height-offset {
  height: calc(100vh - 3.5rem);
}

.form-link {
  display: inline-block;
  margin-top: 0.75rem;
}

.form-control-single-number {
  font-size: 1.75rem;
  text-align: center;
}

.form-control .custom-control-label {
  width: 100%;
}

.changePassTarget {
  cursor: pointer;
  background: transparent;
  left: auto !important;

  .changePassIcon {
    font-size: 1.2em !important;
    color: var(--label);
  }
}

.input-group {
  .form-control {
    padding: 0.5em 1em;
    margin-left: 0px !important;
  }
}

.input-group-merge {
  position: relative;

  .form-control {
    padding: 0.5em 2.75em !important;
  }

  .input-group-prepend {
    position: absolute;
    z-index: 9;
    top: 1px;
    bottom: 1px;
    left: 1px;
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: var(--text-color-dark);
  }

  .input-group-append {
    position: absolute;
    z-index: 9;
    top: 1px;
    bottom: 1px;
    right: 1px;
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: var(--text-color-dark);
  }
}

// .input-group-merge .form-select:not(:first-child)
.input-group-merge .form-control:not(:first-child) {
  padding-left: 3.25rem;
  border-top-left-radius: var(--fc-border-radius);
  border-bottom-left-radius: var(--fc-border-radius);
}

// .input-group-merge .form-select:not(:last-child)
.input-group-merge .form-control:not(:last-child) {
  padding-right: 3.25rem;
  border-top-right-radius: var(--fc-border-radius);
  border-bottom-right-radius: var(--fc-border-radius);
}

.input-group-merge .form-control-sm,
.input-group-merge .form-select-sm {
  min-height: calc(1.5em + 0.875rem);
}

.input-group-merge .form-control-lg,
.input-group-merge .form-select-lg {
  min-height: calc(1.5em + 1.375rem);
}

.form-label {
  color: var(--form-label-color);
  font-size: var(--form-label-fs);
  display: inline-flex;
  gap: 0.1rem;
}

.form-control.is-valid {
  background-size: 1rem 1rem;
}

.form-control.is-valid,
.form-control.is-valid:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}

.form-control.is-invalid {
  background-size: 1rem 1rem;
}

.form-control.is-invalid,
.form-control.is-invalid:focus {
  box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
}

// Toggle Switch CSS
.toggle-switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.toggle-switch:not(.form-block) {
  margin-bottom: 0;
}

.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.5rem;
}

.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: var(--light);
  background-clip: content-box;
  border: 0.125rem solid transparent;
  border-radius: 6.1875rem;
  transition: 0.3s;
}

.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--gray-500);
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;

  &.active {
    background-color: var(--primary) !important;
  }
}

.toggle-switch-input:checked + .toggle-switch-label {
  background-color: var(--white);
}

.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(1.025rem, 50%, 0);
  transform: translate3d(1.025rem, 50%, 0);
}

.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: var(--success);
}

.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: var(--danger);
}

.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, 0.5);
}

.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(var(--blue-rgb), 0.5);
}

.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem;
}

.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem;
}

.toggle-switch-sm
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  -webkit-transform: translate3d(0.81094rem, 50%, 0);
  transform: translate3d(0.81094rem, 50%, 0);
}
// END Toggle Switch CSS

// Icon CSS
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: var(--border-radius);
}

.icon-btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
}

.icon-circle {
  border-radius: 50%;
}

.icon-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.icon.icon-xs {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.icon.icon-sm {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.icon.icon-lg {
  font-size: 1.53125rem;
  width: 3.9375rem;
  height: 3.9375rem;
}

.icon-primary {
  color: var(--white);
  border-color: var(--primary);
  background-color: var(--primary);
}

.icon-secondary {
  color: var(--white);
  border-color: var(--secondary);
  background-color: var(--secondary);
}

.icon-success {
  color: var(--white);
  border-color: var(--success);
  background-color: var(--success);
}

.icon-info {
  color: var(--white);
  border-color: #00c9db;
  background-color: #00c9db;
}

.icon-warning {
  color: var(--text-color-dark);
  border-color: var(--warning);
  background-color: var(--warning);
}

.icon-danger {
  color: var(--white);
  border-color: var(--danger);
  background-color: var(--danger);
}

.icon-light {
  color: var(--text-color-dark);
  border-color: #f9fafc;
  background-color: #f9fafc;
}

.icon-dark {
  color: var(--white);
  border-color: #132144;
  background-color: #132144;
}

.icon-soft-primary {
  color: var(--primary);
  background: rgba(var(--blue-rgb), 0.1);
}

.icon-soft-secondary {
  color: var(--secondary);
  background: rgba(113, 134, 157, 0.1);
}

.icon-soft-success {
  color: var(--success);
  background: rgba(0, 201, 167, 0.1);
}

.icon-soft-info {
  color: #00c9db;
  background: rgba(0, 201, 219, 0.1);
}

.icon-soft-warning {
  color: var(--warning);
  background: rgba(245, 202, 153, 0.1);
}

.icon-soft-danger {
  color: var(--danger);
  background: rgba(237, 76, 120, 0.1);
}

.icon-soft-light {
  color: #f9fafc;
  background: rgba(249, 250, 252, 0.1);
}

.icon-soft-dark {
  color: #132144;
  background: rgba(19, 33, 68, 0.1);
}
// END Icon CSS

.nav-title {
  display: block;
  color: var(--text-color-dark);
  font-size: 1.14844rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.nav-subtitle {
  display: block;
  color: var(--caption-color);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

// Nav Tabs CSS
.nav-tabs-light .nav-item.show .nav-link,
.nav-tabs-light .nav-link.active {
  color: var(--white);
}

.nav-tabs-light .nav-link {
  color: rgba(var(--black-rgb), 0.7);
}

.nav-tabs-light .nav-link:hover {
  color: var(--white);
}
// END Nav Tabs CSS

// Nav Pills CSS
.nav-pills .nav-item:not(:first-child) {
  margin-left: 0.25rem;
}

.nav-pills .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}

.nav-pills:not(.nav-segment) .nav-link:hover {
  color: var(--text-color-light);
  background-color: rgba(189, 197, 209, 0.3);
}

.nav-pills:not(.nav-segment) .nav-link.active {
  color: var(--white);
  background-color: var(--primary);
}
// Nav Pills CSS

.nav-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 0.0625rem solid var(--light);
}

.nav-link-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link-toggle::after,
.nav-link-toggle[data-toggle="collapse"]::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-left: auto;
}

.nav-pills .show > .nav-link-toggle:not(:hover)::after,
.nav-pills .show > .nav-link-toggle:not(:hover)[data-toggle="collapse"]::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}

.nav-item.active .nav-link {
  color: var(--primary);
}

.nav-icon {
  font-size: 1.125rem;
  line-height: 1.4;
  opacity: 0.7;
  -ms-flex: 0 0 1.75rem;
  flex: 0 0 1.75rem;
}

@media (max-width: 575.98px) {
  .nav-sm-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .nav-md-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 991.98px) {
  .nav-lg-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .nav-xl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 1399.98px) {
  .nav-xxl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
}

.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f8fafd;
  width: 0.5rem;
  height: 12.5rem;
}

.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1.75rem 2rem;
  border-radius: 0.75rem;
}

.profile-cover-content {
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
}

.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: var(--light);
  border-radius: 0.75rem;
}

.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.75rem;
}

.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  margin: -4.3rem auto 0.5rem auto;
}

.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .profile-cover {
    height: 6.25rem;
  }

  .profile-cover-img-wrapper {
    height: 6.25rem;
  }

  .profile-cover-img {
    height: 6.25rem;
  }
}

@media (min-width: 768px) {
  .modal-lg .modal-header,
  .modal-xl .modal-header {
    padding-top: 2.25rem;
  }

  .modal-lg .modal-footer,
  .modal-lg .modal-header,
  .modal-xl .modal-footer,
  .modal-xl .modal-header {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
  }

  .modal-lg .modal-body,
  .modal-xl .modal-body {
    padding: 2.25rem;
  }

  .modal-lg .modal-footer,
  .modal-xl .modal-footer {
    padding-bottom: 2.25rem;
  }
}

.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}

.modal-top-cover-avatar,
.modal-top-cover-icon {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.border-left-primary {
  border-left-color: var(--primary) !important;
}

.border-left-secondary {
  border-left-color: var(--secondary) !important;
}

.border-left-success {
  border-left-color: var(--success) !important;
}

.border-left-info {
  border-left-color: #00c9db !important;
}

.border-left-warning {
  border-left-color: var(--warning) !important;
}

.border-left-danger {
  border-left-color: var(--danger) !important;
}

.border-left-light {
  border-left-color: #f9fafc !important;
}

.border-left-dark {
  border-left-color: #132144 !important;
}

.opacity-xs {
  opacity: 0.2;
}

.opacity-sm {
  opacity: 0.4;
}

.opacity {
  opacity: 0.6;
}

.opacity-lg {
  opacity: 0.8;
}

.right-0 {
  right: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.min-h-100 {
  min-height: 100%;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh;
  }

  .min-vh-lg-100 {
    min-height: 100vh;
  }
}

// Date-range Picker CSS
.daterangepicker-custom-input {
  cursor: pointer;
}

.daterangepicker-wrapper {
  border: none;
  padding: 0;
  cursor: pointer;
}

.daterangepicker {
  border: none;
  font-family: "Open Sans", sans-serif;
  z-index: 98;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.75rem;
}

.daterangepicker::before {
  border-bottom-color: transparent;
}

.daterangepicker .drp-calendar {
  max-width: 18.75rem;
}

.daterangepicker .drp-calendar.left {
  padding: 1rem 1rem;
}

.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}

.daterangepicker .calendar-table table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}

.daterangepicker .calendar-table th.next:hover,
.daterangepicker .calendar-table th.prev:hover {
  background-color: rgba(var(--blue-rgb), 0.1);
}

.daterangepicker .calendar-table th.next:hover span,
.daterangepicker .calendar-table th.prev:hover span {
  color: var(--primary);
}

.daterangepicker .calendar-table th.next span,
.daterangepicker .calendar-table th.prev span {
  position: relative;
  border: none;
  color: var(--text-color-light);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  padding: 0;
}

.daterangepicker .calendar-table th.next,
.daterangepicker .calendar-table th.prev {
  min-width: auto;
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
}

.daterangepicker .calendar-table th.next span::before,
.daterangepicker .calendar-table th.prev span::before {
  display: inline-block;
  background-color: var(--text-color-light);
  width: 0.6125rem;
  height: 0.6125rem;
  content: "";
}

.daterangepicker .calendar-table th.prev span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.prev:hover span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.next span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th.next:hover span::before {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='%23556ff6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z' clip-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat right center/.6125rem 0.6125rem;
}

.daterangepicker .calendar-table th:not(.month) {
  color: var(--fc-input-placeholder);
  text-transform: uppercase;
}

.daterangepicker .calendar-table th.month {
  font-size: 0.875rem;
  font-weight: 600;
}

.daterangepicker .calendar-table td {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem;
  line-height: 1.9375rem;
}

.daterangepicker td.available:not(.in-range) {
  border-radius: 50%;
}

.daterangepicker td.available:hover:not(.active) {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.daterangepicker td.in-range,
.daterangepicker td.in-range.available:hover {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.daterangepicker td.today.start-date.end-date {
  border-radius: 50%;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: var(--white);
  background-color: var(--primary);
}

.daterangepicker td.active.start-date,
.daterangepicker td.active:hover.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.daterangepicker td.active.end-date,
.daterangepicker td.active:hover.end-date {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range {
  color: var(--light);
}

.daterangepicker .drp-buttons,
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-color: var(--light);
}

.daterangepicker .drp-buttons {
  padding: 1rem 1rem;
}

.daterangepicker .drp-buttons .btn {
  font-weight: 400;
  padding: 0.4375rem 0.65625rem;
}

.daterangepicker .cancelBtn {
  background-color: var(--white);
  border-color: var(--light);
}

.daterangepicker .cancelBtn.active,
.daterangepicker .cancelBtn:active,
.daterangepicker .cancelBtn:focus,
.daterangepicker .cancelBtn:hover {
  color: var(--primary-hover);
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.daterangepicker .drp-selected {
  color: var(--secondary);
}

@media (max-width: 575.98px) {
  .daterangepicker .drp-selected {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.daterangepicker .ranges ul {
  min-width: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.daterangepicker .ranges li {
  color: var(--secondary);
  font-size: 0.875rem;
  line-height: 1.6;
  padding: 0.375rem 1.5rem;
}

.daterangepicker .ranges li:hover {
  color: var(--primary-hover);
  background-color: transparent;
}

.daterangepicker .ranges li.active {
  color: var(--primary);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background-color: transparent;
}
// END Date-range Picker CSS
