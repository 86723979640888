/* You can add global styles to this file, and also import other style files */
// Modified Styles with in this project

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

// Skeltonloader CSS
.skeletonloader-wapper {
  .wrapper {
    font-size: 8px !important;
  }
}

#c_dail_code .ng-select-container {
  min-width: 80px;
  width: 100%;
}

.btn .bi {
  display: inline-flex;
}

.read-only,
.read-only * {
  pointer-events: none !important;
}

.skeleton-loader {
  margin-bottom: 0px !important;
  // height: 15px !important;
}
// END Skeltonloader CSS

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-16);
  padding-bottom: var(--gap-16);
}

textarea {
  border-radius: var(--border-radius) !important;
}

svg {
  fill: var(--primary);
  // filter: invert(19%) sepia(84%) saturate(1721%) hue-rotate(266deg)
  //   brightness(94%) contrast(97%);
}

.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  font-weight: 500 !important;
}

// Login SCSS
.auth_title_sec {
  margin-bottom: 2.5rem;

  .login_title {
    font-size: 1.8rem;
  }
}
// END Login SCSS

// Header/Sidenav Brand Logo and User Info CSS
.brand_logo_sec {
  border-radius: 0.625rem;
  background: var(--white);
  position: relative;
  text-align: center;

  .logo-img {
    width: auto;
    height: 60px;
    object-fit: contain;
  }
}

.user_info_sec {
  display: flex;
  align-items: center;
  gap: var(--gap-10);

  .avatar {
    border: 2px solid var(--gray-300);
    padding: 0.2rem;
  }

  .user_info {
    width: 100%;
    max-height: 80px;
    overflow: auto;
    word-wrap: break-word;
  }
}
// END Header/Sidenav Brand Logo and User Info CSS

#accountNavbarDropdown {
  display: flex;
  flex-direction: column;
  max-height: 280px;
}

.map-container {
  height: 250px !important;
  width: 100% !important;
  border-radius: var(--card-border-radius) !important;
}

.mat-mdc-menu-panel {
  min-width: 260px !important;
  max-width: 300px !important;
  border-radius: var(--card-border-radius) !important;
  border: var(--card-border) !important;
  background: var(--white) !important;
  box-shadow: 0px 16px 24px 0px rgba(7, 18, 63, 0.25) !important;
}

.mat-mdc-menu-item.mdc-list-item {
  border-radius: var(--fc-border-radius);
}

.mat-mdc-menu-item:not([disabled]) {
  &:hover,
  &.active {
    background-color: rgba(var(--primary-rgb), 0.15) !important;
    .mat-mdc-menu-item-text {
      font-weight: 800;
    }
  }
}

.mat-mdc-menu-item {
  padding: 0.5rem 1rem !important;
  min-height: 40px !important;

  .mat-mdc-menu-item-text {
    font-size: 0.8rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
  }

  .mat_menu_icon {
    display: flex;
    font-size: 1rem;
    color: var(--primary);
    width: 1rem;
    height: 1rem;
    aspect-ratio: 1/1;
  }
}

.mat-divider {
  border-top-color: var(--light) !important;
}

#headerMain > #header {
  box-shadow: var(--fc-box-shadow);
}

.bi-upload::before {
  font-weight: bolder !important;
}

.ng-option-disabled {
  pointer-events: none;
  cursor: not-allowed !important;
}

.gd_icon {
  font-size: 1.2em;
  aspect-ratio: 1/1;

  &::before {
    vertical-align: -00.2em !important;
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.n_icon {
  font-size: 1.25rem;
  color: var(--text-color-dark);
}

.w_icon {
  &:not(:disabled):hover {
    background: var(--gradient);
    color: var(--white);
  }
}

// Mat Tooltip
.mat-mdc-tooltip {
  border-radius: var(--border-radius) !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--secondary) !important;
  padding: 0px !important;
  scrollbar-color: transparent transparent;
  min-height: auto !important;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  &:hover {
    scrollbar-color: initial;

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.invalid-input,
.invalid-input:focus {
  border-color: var(--danger);
}

@media screen and (min-width: 1200px) {
  .campaign_preview,
  .template_preview {
    .preview_inside {
      position: fixed !important;
    }
  }
}

// Infobox CSS
.info-box {
  display: flex;
  align-items: start;
  gap: 0.5rem;
  overflow-wrap: anywhere;

  .info_icon em {
    display: flex;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    aspect-ratio: 1/1;
  }

  // .info_details {
  //   text-transform: capitalize;
  // }
}
// END Infobox CSS

.welcome_title {
  font-size: 30px;
  font-weight: var(--fw-9);
}

.welcome_sub_title {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
}

.avatar-uploader {
  .avatar_icon {
    display: flex;
    background: var(--gradient);
    color: var(--white);
    padding: 0.5rem;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

// .avatar {
//   &.avatar-bg {
//     background: var(--gray-100);
//     padding: 0.8rem;
//     // border-radius: 50%;
//   }
// }

.business_avatar {
  position: relative;

  .active_status {
    position: absolute;
    left: 10%;
  }
}

.skeleton_loader {
  img {
    top: 40vh !important;
    width: 60px !important;
    height: 60px !important;
    opacity: 0.6 !important;
  }
}

// Page/Event Loader
.loader {
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  height: 20px;
  z-index: 999;
  bottom: 0;
  top: 0;
  margin: auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--text-color-light);
  box-shadow: 32px 0 var(--text-color-light);
  left: 0;
  top: 0;
  animation: ballMoveX 1s linear infinite;
}

.loader:after {
  box-shadow: none;
  transform: translateX(64px) scale(1);
  z-index: 2;
  animation: none;
  animation: trfLoader 1s linear infinite;
}

@keyframes trfLoader {
  0%,
  5% {
    transform: translateX(64px) scale(1);
    background: var(--text-color-light);
  }

  10% {
    transform: translateX(64px) scale(1);
    background: var(--primary);
  }

  40% {
    transform: translateX(32px) scale(1.5);
    background: var(--primary);
  }

  90%,
  95% {
    transform: translateX(0px) scale(1);
    background: var(--primary);
  }

  100% {
    transform: translateX(0px) scale(1);
    background: var(--text-color-light);
  }
}

@keyframes ballMoveX {
  0%,
  10% {
    transform: translateX(0);
  }

  90%,
  100% {
    transform: translateX(32px);
  }
}
// END Page/Event Loader

.spinner_load {
  top: 10% !important;
}

.spinner-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(19, 33, 68, 0.25);
  // background-color: rgb(213 213 213 / 0%);
}

.card-sticky {
  position: sticky;
  top: 5.3em;
  z-index: 99;
  transition: ease-out top 5s;
}

// Date Range selector
ngb-datepicker.dropdown-menu {
  width: auto !important;
}

.ngb-dp-header {
  padding: 10px 0 !important;
}

.ngb-dp-arrow {
  display: flex;
  align-items: center;
}

.ngb-dp-weekday {
  color: var(--primary) !important;
  font-weight: bold;
}

.ngb-dp-day {
  margin: 1px;
}

.ngb-dp-today .custom-day {
  background-color: rgba(var(--secondary-rgb), 0.2) !important;
  border-radius: var(--fc-border-radius);
}

.edit_option {
  cursor: pointer;
  font-size: 0.2em;
  background: rgba(var(--blue-rgb), 0.1) !important;

  &:hover {
    background: rgba(var(--blue-rgb), 0.3) !important;
    box-shadow: var(--box-shadow) !important;
  }
}

.btn-option {
  font-size: 0.8em;
  color: var(--text-color-light);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 0;
  padding: 14px;

  &:hover {
    background-color: rgba(52, 143, 251, 0.15);
  }

  &:active {
    border: 0;
    background-color: rgba(52, 143, 251, 0.15) !important;
    color: var(--text-color-light) !important;
  }

  .bi::before {
    font-weight: bold !important;
  }
}

.ch_item {
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 5px;
  flex: 1 0 0;
  min-height: 65px;
  border-radius: var(--fc-border-radius);
  background: var(--fc-background);
  box-shadow: var(--fc-box-shadow);

  &.disabled {
    opacity: 0.5;
    pointer-events: auto;
    cursor: not-allowed;

    .form-check {
      pointer-events: none;
    }
  }

  img {
    width: 15%;
  }
}

.channel_sec,
.bsp_sec {
  .slct_item {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 15px;
    flex: 1 0 0;
    min-height: 65px;
    border-radius: var(--fc-border-radius);
    background: var(--white);
    box-shadow: 0px 0px 8px 0px var(--box-shadow);

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.ba_channel {
  .ng-select-container {
    background: var(--gradient) !important;
    border: none;

    .ng-value-container {
      color: var(--white) !important;

      .ng-placeholder {
        color: var(--white) !important;
      }
    }
  }

  .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  img {
    width: 15%;
  }

  .ng-arrow-wrapper .ng-arrow,
  .ng-arrow-wrapper .ng-arrow:hover {
    border-color: var(--white) transparent transparent !important;
  }

  &.ng-select-opened > .ng-select-container .ng-arrow,
  &.ng-select-opened > .ng-select-container .ng-arrow:hover {
    border-color: transparent transparent var(--white) !important;
  }
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: var(--font-family) !important;
  line-height: var(--body-line-height) !important;
  font-size: var(--body-font-size) !important;
  letter-spacing: var(--body-letter-spacing) !important;
  font-weight: var(--body-font-weight) !important;
}

// Toggle Slider CSS
.slider {
  &.mat-mdc-slide-toggle {
    .mdc-switch {
      .mdc-switch__track {
        height: 1rem;
      }

      .mdc-switch__handle-track {
        // margin-left: 0.25rem;
        // width: calc(100% - var(--mdc-switch-handle-width, 28px));
      }

      &:enabled {
        .mdc-switch__track {
          &::after {
            // background: var(--primary) !important;
          }
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__handle {
            &::after {
              // background-color: var(--white) !important;
            }
          }

          .mdc-switch__icon {
            fill: var(--white) !important;
          }
        }
      }

      &.mdc-switch--unselected {
        .mdc-switch__handle {
          &::after {
            // background-color: var(--white) !important;
          }
        }

        &:enabled {
          .mdc-switch__icon {
            // background: var(--white) !important;
            // border-radius: 50% !important;
          }
        }
      }
    }
    // END Template Slider
  }
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary);
  --mdc-switch-selected-hover-state-layer-color: var(--primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary);

  --mdc-switch-selected-handle-color: var(--primary) !important;
  --mdc-switch-selected-focus-handle-color: var(--primary) !important;
  --mdc-switch-selected-hover-handle-color: var(--primary) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primary) !important;

  --mdc-switch-selected-focus-track-color: rgba(
    var(--primary-rgb),
    0.4
  ) !important;
  --mdc-switch-selected-hover-track-color: rgba(
    var(--primary-rgb),
    0.4
  ) !important;
  --mdc-switch-selected-pressed-track-color: rgba(
    var(--primary-rgb),
    0.4
  ) !important;
  --mdc-switch-selected-track-color: rgba(var(--primary-rgb), 0.2) !important;
}

.form-check .form-check-input {
  margin-top: 0;
  margin-left: -1.25em !important;
}
// END Toggle Slider CSS

.setup_form_sec,
.create_form_sec {
  padding: 10px 30px;
}

.whatsapp_preview {
  background: var(--text-active);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  padding: 20px;
  height: 100%;
  position: relative;
}

.header_select {
  .ng-select-container {
    height: var(--fc-height) !important;
  }
}

.back_to_login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 700;
}

.filter-dropdown {
  .btn {
    &:active {
      border-color: var(--primary) !important;
    }
  }

  .dropdown-menu {
    min-width: 300px;
    padding: 0px !important;
    width: max-content;

    .card {
      border: var(--fc-border) !important;
      padding: 0;
    }

    .card-header,
    .card-body,
    .card-footer {
      padding: 1rem;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      gap: var(--gap-16);

      ng-select:not[last-child] {
        margin-bottom: 10px;
      }
    }
  }
}

// Filltered Chips
.selected_filters {
  background: var(--gray-100);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.selected_items {
  .mdc-evolution-chip-set__chips {
    gap: var(--gap-10);
    margin-left: 0 !important;
  }

  .chips {
    background: rgba(var(--blue-rgb), 0.15);
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: rgba(var(--blue-rgb), 0.15) !important;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  margin: 0 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action {
  .mdc-evolution-chip__action--primary {
    padding-left: 0 !important;
  }

  .mdc-evolution-chip__action--trailing {
    padding-left: 8px;
    padding-right: 1px;
    font-family: var(--font-family) !important;
    font-size: var(--body-font-size) !important;
    font-weight: var(--fw-6) !important;
    color: var(--text-color-dark) !important;
    letter-spacing: 0px !important;
  }
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-family: var(--font-family) !important;
  font-size: var(--body-font-size) !important;
  font-weight: var(--fw-6) !important;
  color: var(--text-color-dark) !important;
  letter-spacing: 0px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input::placeholder,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder {
  font-size: 0.785rem;
  font-weight: var(--fw-6);
  color: var(--fc-input-placeholder);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: var(--fc-border-color) !important;
  border-radius: var(--fc-border-radius) !important;
}

.mdc-notched-outline__leading {
  width: 100%;
}

.mdc-text-field--outlined {
  padding: 0 !important;
  font-size: var(--body-font-size);
  font-weight: var(--fw-6);
  color: var(--text-color-dark);

  .mdc-notched-outline {
    background: var(--fc-background);
    z-index: 0 !important;

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      width: inherit !important;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0.5em 1em !important;
  min-height: 42px !important;
  display: flex;
  align-items: center;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined::placeholder {
  font-size: 0.785rem;
  font-weight: normal;
  color: var(--fc-input-placeholder) !important;
}

// END Filltered Chips

// Dashboard CSS
#dashboard-wrapper {
  .welcome-card {
    background-color: rgba(var(--primary-rgb), 0.1);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;

    .welcome-title {
      line-height: 1;
    }
  }
}

.top_header_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.6rem;

  .main_title {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0px;
  }

  .selection {
    min-width: 200px;
    background: var(--fc-background);

    .ng-select-container {
      min-height: 42px !important;
    }

    .ng-dropdown-panel {
      width: 100% !important;
    }
  }

  #navBtns {
    margin-right: 2rem;
    font-weight: var(--fw-7);
    background: var(--background-3) !important;

    .nav-link {
      margin: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: var(--fc-border-radius);
      color: var(--dark);

      &.active {
        border: none;
        color: var(--white);
        background: #283744 !important;
        border-color: transparent;
      }
    }
  }
}

.top_header_stats_options {
  border-bottom: 1px solid var(--light);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.stats_sec {
  align-items: center;
  flex-direction: row;

  .stats_item {
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    padding: 1.25rem;
    min-width: 120px;
    background: var(--white);
    box-shadow: var(--card-box-shadow);
    display: flex;
    flex-direction: column;
    gap: var(--gap-10);
    overflow: hidden;
    overflow-wrap: break-word;

    .show_info {
      display: flex;
      justify-content: space-between;
    }

    .stats_info_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      padding: 0.8rem;
      border-radius: 0.563rem;

      &.email-sent,
      &.total-calls,
      &.recipients,
      &.answered {
        color: rgba(var(--dark-magenta-rgb), 1);
        background: rgba(var(--dark-magenta-rgb), 0.1);
      }

      &.patched {
        color: rgb(75, 83, 116);
        background: rgb(241, 233, 244);
      }

      &.submitted {
        color: rgba(var(--info-rgb), 1);
        background: rgba(var(--info-rgb), 0.1);
      }

      &.opt-out,
      &.abandoned {
        color: rgba(var(--warning-rgb));
        background: rgba(var(--warning-rgb), 0.1);
      }

      &.sent,
      &.outbound,
      &.dialouts {
        color: rgba(var(--label-rgb), 1);
        background: rgba(var(--label-rgb), 0.1);
      }

      &.delivered,
      &.inbound,
      &.uploaded,
      &.received {
        color: rgba(var(--success-rgb), 1);
        background: rgba(var(--success-rgb), 0.1);
      }

      &.read,
      &.email-opened,
      &.connected {
        color: rgba(var(--blue-rgb), 1);
        background: rgba(var(--blue-rgb), 0.1);
      }

      &.unread {
        color: rgba(var(--iris-blue-rgb), 1);
        background: rgba(var(--iris-blue-rgb), 0.1);
      }

      &.pending {
        color: rgba(var(--pink-rgb), 1);
        background: rgba(var(--pink-rgb), 0.1);
      }

      &.failed,
      &.email-bounces {
        color: var(--danger);
        background: rgba(var(--danger-rgb), 0.1);
      }
    }

    .stats_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.875rem;
      height: 1.875rem;
      font-size: 1.875rem;

      &::before {
        font-weight: 800 !important;
      }
    }

    .stats_title {
      color: var(--gray-light);
      font-size: 0.75rem;
      font-weight: 600;
    }

    .lead {
      color: var(--text-color-dark-2);
      font-size: 1.25rem;
      font-weight: 800;
      line-height: 1;
    }
  }
}

// Status Indicators CSS
.status_indicator,
.wallet-type-badge {
  display: flex;
  align-items: center;
  gap: 0.4em;
  width: fit-content;
  white-space: nowrap;
  padding: 0.3rem 0.625rem;
  border-radius: var(--border-radius);
  font-size: 85%;
  font-weight: 700;
  text-transform: capitalize;

  .status_icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
  }

  &.preprocessed,
  &.paused,
  &.pause,
  &.pending,
  &.scheduled,
  &.new {
    background: rgba(var(--primary-rgb), 0.1);

    .status_icon {
      background: var(--primary) !important;
    }

    .status_text {
      color: var(--primary) !important;
    }
  }

  &.success,
  &.sent,
  &.approved,
  &.delivered,
  &.completed,
  &.credit,
  &.active {
    background: rgba(var(--success-rgb), 0.1);

    .status_icon {
      background: var(--success) !important;
    }

    .status_text {
      color: var(--success) !important;
    }
  }

  &.failed,
  &.rejected,
  &.inactive {
    background: rgba(var(--danger-rgb), 0.1);

    .status_icon {
      background: var(--danger) !important;
    }

    .status_text {
      color: var(--danger) !important;
    }
  }

  &.opt-out,
  &.inprogress,
  &.preprocessing,
  &.expired,
  &.postpaid {
    background: rgba(var(--warning-rgb), 0.1);

    .status_icon {
      background: var(--warning) !important;
    }

    .status_text {
      color: var(--warning) !important;
    }
  }

  &.ongoing,
  &.submitted {
    background: rgba(var(--info-rgb), 0.1);

    .status_icon {
      background: var(--info) !important;
    }

    .status_text {
      color: var(--info) !important;
    }
  }

  &.drafted,
  &.draft,
  &.prepaid,
  &.debit,
  &.update {
    background: rgba(var(--blue-rgb), 0.1);

    .status_icon {
      background: var(--blue) !important;
    }

    .status_text {
      color: var(--blue) !important;
    }
  }

  &.read,
  &.renew {
    background: rgba(var(--deep-blue-rgb), 0.1);

    .status_icon {
      background: var(--deep-blue) !important;
    }

    .status_text {
      color: var(--deep-blue) !important;
    }
  }
}
// END Status Indicators CSS

.stats_graph {
  display: flex;
  justify-content: center;

  .chart {
    border-radius: var(--card-border-radius);
    max-height: 600px;
    height: fit-content !important;
    padding: 1.5rem 1rem;
    background: var(--fc-select-bg);
  }
}

// Chart CSS
canvas {
  // min-width: 500px;
  width: 100%;
  height: auto;
  max-height: 400px;
  min-height: 200px;
}

.point {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  align-self: center;

  &--email-sent,
  &--total-calls,
  &--answered {
    background-color: var(--dark-magenta);
  }

  &--submitted {
    background-color: var(--info);
  }

  &--opt-out,
  &--abandoned {
    background-color: var(--warning);
  }

  &--sent,
  &--outbound,
  &--dialouts,
  &--patched {
    background-color: var(--label);
  }

  &--delivered,
  &--inbound,
  &--received,
  &--recipients {
    background-color: var(--success);
  }

  &--read,
  &--opened,
  &--connected {
    background-color: var(--blue);
  }

  &--unread {
    background-color: var(--iris-blue);
  }

  &--pending {
    background-color: var(--pink);
  }

  &--failed,
  &--bounces {
    background-color: var(--danger);
  }
}

.chart_btns {
  .btn-link {
    gap: 0.5rem;
    padding: 0.5rem;
  }
}
// END Chart CSS
// END Dashboard CSS

// Wallat Balance Information Card CSS
.wallet-balance-info {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--gap-16);
  height: 100%;
  background: var(--secondary) url(/images/png/wallet-bg.png) no-repeat left
    top/cover;

  &.secondary_wallet {
    background: var(--primary) url(/images/png/wallet-bg.png) no-repeat left
      top/cover;
  }

  label {
    color: var(--white);
    margin-bottom: 0;
  }

  .wallet_icon {
    position: absolute;
    right: 20px;
    .icon {
      width: 60px;
      height: 100%;
      opacity: 0.5;
    }
  }
}
// Wallat Balance Information Card CSS

// Search Bar
.search_bar {
  .form-control {
    min-height: 42px !important;
    box-shadow: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
    background: transparent !important;
  }
}
// END Search Bar

.cst_notifications a .bell_animate {
  animation: clapp 1s linear infinite;
}

// Table Presentation CSS
.table-wrapper {
  overflow: hidden;
  overflow-x: auto !important;
  // margin-top: -0.8rem;

  .table {
    margin-bottom: 0;
    thead {
      background-color: var(--table-head-bg);
      height: 45px;

      th {
        color: var(--text-color-dark);
        label {
          margin-bottom: 0;
        }
      }

      th:last-child.action_col {
        text-align: center !important;
      }

      .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(
          :indeterminate
        ):not([data-indeterminate="true"])
        ~ .mdc-checkbox__background {
        border-color: var(--white);
      }

      .mdc-checkbox
        .mdc-checkbox__native-control:enabled
        ~ .mdc-checkbox__background
        .mdc-checkbox__mixedmark {
        border-color: var(--white) !important;
      }
    }

    .table-row {
      font-weight: 400;

      th,
      td {
        background: inherit;
        vertical-align: middle !important;
        border: 0;
      }
      th:first-child {
        border-bottom: 0px;
      }

      .cmp_select {
        display: flex;
        align-items: center;
      }
    }

    .table-body {
      border: var(--table-border);

      .table-row {
        &:hover {
          background: var(--gray-100) !important;
        }

        td {
          border: none;
          box-shadow: none;

          // &:last-child {
          //   text-align: center;
          // }

          &.table-data {
            &:first-child {
              font-weight: bold;
              text-transform: capitalize;
            }

            .wallet_icon {
              background-color: #f3f4f6;
              border-color: var(--secondary-light);
              border-radius: var(--border-radius);
              .transation_direction {
                position: absolute;
                right: -5px;
                bottom: -5px;
              }
            }
          }
        }

        .action_options {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .table-striped {
    border-collapse: separate;
    border-spacing: 0 0.314rem;
    thead {
      th {
        background: var(--table-head-bg);
        padding: 0 1rem;

        &:first-child {
          border-top-left-radius: var(--table-border-radius);
          border-bottom-left-radius: var(--table-border-radius);
        }
        &:last-child {
          border-top-right-radius: var(--table-border-radius);
          border-bottom-right-radius: var(--table-border-radius);
          .header-items {
            justify-content: center;
          }
        }

        .header-items {
          display: flex;
          align-items: center;
          font-size: var(--th-font-size);
          font-weight: 600;
        }
      }

      .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(
          :indeterminate
        ):not([data-indeterminate="true"])
        ~ .mdc-checkbox__background {
        border-color: var(--white);
      }

      .mdc-checkbox
        .mdc-checkbox__native-control:enabled
        ~ .mdc-checkbox__background
        .mdc-checkbox__mixedmark {
        border-color: var(--white) !important;
      }
    }

    .table-row {
      td {
        background: transparent;
        color: var(--text-color-dark);
      }

      .cmp_select {
        display: flex;
        align-items: center;
      }
    }

    .table-body {
      .tr {
        .action_options {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      td {
        border-top: var(--table-border);
        border-bottom: var(--table-border);
        padding: 1rem;

        &:first-child {
          // border: var(--table-border);
          border-right: 0;
          border-top-left-radius: var(--table-border-radius);
          border-bottom-left-radius: var(--table-border-radius);
        }
        &:last-child {
          // border: var(--table-border);
          border-left: 0;
          border-top-right-radius: var(--table-border-radius);
          border-bottom-right-radius: var(--table-border-radius);
        }
      }

      .table-row {
        // box-shadow: 0px 0px 5px 0px rgba(26, 26, 26, 0.1);
        background: var(--table-body-row-bg);
        border-radius: var(--table-border-radius);
      }
    }
  }
}

.table-data,
.mat-column-action {
  .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: start;
  }
}
// END Table Presentation CSS

// Checkbox
.list-checkboxes {
  position: relative;
  bottom: 1px;
  margin-left: -0.65rem !important;

  &.mat-mdc-checkbox {
    .mdc-form-field {
      height: 1rem !important;

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        display: none !important;
      }

      .mdc-checkbox {
        .mdc-checkbox__background {
          background: var(--white);
          border-width: 1px;

          .mdc-checkbox__checkmark {
            height: 12px;
            margin-top: 2px;
            color: var(--white) !important;
          }
        }

        .mdc-checkbox__native-control {
          &:enabled {
            &:checked ~ {
              .mdc-checkbox__background {
                border-color: var(--primary);
                background: var(--primary);
              }
            }
          }
        }
      }
    }
  }
}
// END Checkbox

.model_icon {
  font-size: 3em;
}

.modal .modal-dialog .modal-content {
  .warning_text {
    background: rgba(var(--warning-rgb), 0.15);
    padding: 1rem;
    border-radius: var(--fc-border-radius);
    border: 1px solid var(--warning);
  }
}

.mat-mdc-chip-remove .mat-icon {
  line-height: 1.3;
}

// Matradio Button CSS
.mat-mdc-radio-button .mdc-form-field {
  color: var(--text-color-dark) !important;
  font-size: 0.75rem !important;
  font-weight: var(--fw-6) !important;
  font-family: var(--font-family) !important;
  letter-spacing: var(--body-letter-spacing) !important;
}

.mdc-form-field {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;

  label {
    margin: 0;
    margin-right: 0px !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    font-family: var(--font-family) !important;
    letter-spacing: 0;
    order: 0;
  }
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--primary) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--secondary) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--secondary) !important;
}

// END Matradio Button CSS

// File Upload Drop Zone CSS
.upload_dropZone {
  background: var(--fc-background);
}

.media_upload_dropZone {
  background-color: var(--white);
  border: 1px dashed var(--label);
  border-radius: var(--border-radius);
  padding: 10px;
  position: relative;

  &:hover {
    border-color: var(--primary);
  }

  .upload_image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.upload_gallery {
  display: flex;
  align-items: center;

  .uploaded_image {
    position: relative;
    width: 8%;

    .img_close {
      cursor: pointer;
      position: absolute;
      top: -4px;
      left: -4px;
      color: var(--danger);
      font-size: 1em;

      &:hover {
        color: var(--danger);
        transform: scale(1.4);
      }
    }
  }
}
// END File Upload Drop Zone CSS

// Media Drive CSS
.media_seleted {
  border-color: var(--primary);
}

.media_card_header {
  position: absolute;
  width: 100%;
  background: transparent;
}

.preview_min_height {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_wrapper {
  width: 100%;
  height: 100px;

  .media_avatar_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.media_avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: auto;
  aspect-ratio: 1/1;
}
// END Media Drive CSS

.success_screen {
  display: grid;
  place-items: center;
  height: inherit;
  height: 100%;
  max-height: calc(100% - 120px);
}

.campaign_view_details {
  .stats_sec {
    .stats_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5em;
      flex: 1 0 160px !important;

      .stats_info_icon {
        margin: 0;
      }
    }
  }
}

.card_align_items {
  .card {
    .card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .card_ftr_cancel {
        padding: 0.5rem 0.875rem !important;
        line-height: 1.6em !important;
      }

      .card_ftr_cancel:focus {
        background-color: var(--nlu-secondary);
        border-color: var(--nlu-secondary);
        color: var(--white);
      }
    }
  }
}

.form_action_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  .options_left {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .options_right {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    min-width: 120px;
  }
}

// Mat Stepper CSS
.mat-horizontal-stepper-header-container {
  border-bottom: 2px solid var(--gray-200);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.mat-horizontal-stepper-header {
  height: 40px !important;
  border-radius: var(--fc-border-radius) !important;
}

.mat-stepper-horizontal-line {
  margin: auto !important;
  border-top: 2px solid var(--gray-400) !important;
}

.mat-step-icon-selected,
.mat-step-icon-state-edit,
.mat-step-icon-state-done {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
// END Mat Stepper CSS

// Button With loading spinner
.button_text {
  color: #fff;
  transition: all 0.2s;
}

.btn-loading .button_text {
  visibility: hidden;
  opacity: 0;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: var(--white) fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes clapp {
  $_x: 0px;

  0%,
  100% {
    transform: translate($_x, 0px) rotate(0);
  }

  20% {
    transform: translate($_x + 4px, 0px) rotate(-10deg);
  }

  40% {
    transform: translate($_x - 4px, 0px) rotate(10deg);
  }

  60% {
    transform: translate($_x + 3px, 0px) rotate(-8deg);
  }

  80% {
    transform: translate($_x - 3px, 0px) rotate(8deg);
  }
}

// Analyze Loader CSS
.analyze_loader {
  font-size: 18px;
  color: var(--secondary);
  display: inline-block;
  font-weight: 400;
  position: relative;
}

.analyze_loader:before {
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  background: var(--default);
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.analyze_loader:after {
  content: "";
  height: 5px;
  width: 0%;
  display: block;
  background: var(--primary);
  animation: 5s lineGrow linear infinite;
  z-index: 1;
  position: relative;
}

@keyframes lineGrow {
  to {
    width: 100%;
  }
}

// END Analyze Loader CSS

/*!
 * Bootstrap Image Checkbox v0.1.0 (https://iqbalfn.github.io/bootstrap-image-checkbox/)
 */
.custom-control.image-checkbox {
  position: relative;
  padding-left: 0;
}

.custom-control.image-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after,
.custom-control.image-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:before {
  opacity: 1;
}

.custom-control.image-checkbox label {
  cursor: pointer;
}

.custom-control.image-checkbox label:before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-control.image-checkbox label:after,
.custom-control.image-checkbox label:before {
  transition: opacity 0.3s ease;
  opacity: 0;
  left: 0.25rem;
}

.custom-control.image-checkbox label:focus,
.custom-control.image-checkbox label:hover {
  opacity: 0.8;
}

.custom-control.image-checkbox label img {
  border-radius: 2.5px;
}

.form-group-image-checkbox.is-invalid label {
  color: var(--danger);
}

.form-group-image-checkbox.is-invalid .invalid-feedback {
  display: block;
}

.p-element {
  .p-calendar {
    .p-inputtext {
      min-height: 48px !important;
      min-width: 150px;
      background: var(--fc-select-bg);
      font-weight: var(--fw-6);
      border-radius: var(--fc-border-radius) !important;
      border: var(--fc-border) !important;
      outline: none !important;
      appearance: none !important;
      padding: 10px;
    }

    .p-button-icon-only {
      background: var(--primary);
      border: var(--primary);
      border-top-right-radius: var(--fc-border-radius);
      border-bottom-right-radius: var(--fc-border-radius);

      .p-icon-wrapper {
        color: var(--white);

        svg {
          filter: none;
        }
      }
    }
  }
}

.p-datepicker {
  .p-datepicker-group-container {
    padding: 10px;

    .p-datepicker-header {
      padding-bottom: 10px;
    }

    .p-datepicker-calendar {
      margin-top: 10px;
    }
  }
}

.p-timepicker {
  button {
    color: var(--primary);
  }
}

.p-datepicker-buttonbar {
  padding: 10px;
}

.mat-badge-content {
  background: var(--primary) !important;
}

/* mat-button {
  display: inline-block;
  font-weight: 400;
  color: var(--text-color-light);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: var(--fc-border-radius);
  transition: all 0.2s ease-in-out;
} */

.mat-button {
  line-height: 40.4px !important;
  border-radius: 5rem !important;
  border: 0.0625rem solid var(--light);
}

.mat-icon-button {
  padding: 0 !important;
  min-width: 0;
  width: 25px !important;
  height: 25px !important;
  flex-shrink: 0;
  line-height: 0px !important;
  border-radius: 50%;
  vertical-align: middle !important;
}

.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
  vertical-align: unset !important;
}

// th:first-child, td:first-child
// {
//   position:sticky;
//   left:0px;
//   // background-color:var(--white)!important;
//   z-index: 1;
//   box-shadow: 5px 5px 5px #000 !important;
// }

// Accordion CSS
.accordion {
  display: flex;
  flex-direction: column;
  gap: var(--gap-10);
}
.accordion-item {
  border: var(--fc-border) !important;
  border-radius: var(--border-radius) !important;

  .accordion-button {
    border-radius: var(--border-radius) !important;
  }
}

.accordion-button {
  color: var(--text-color-dark);
  padding: 0.75rem 1rem;
  font-size: 0.825rem;
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--body-font-size);
  height: var(--body-font-size);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--body-font-size);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:not(.collapsed) {
  color: var(--text-color-dark);
  background-color: var(--fc-background);
  box-shadow: none;
  border-bottom: 1px solid var(--gray-400);
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.accordion-body {
  padding: 0.75rem 1rem;
  background-color: var(--fc-background);
  border-radius: var(--border-radius);

  .card {
    padding: 1rem;
    border: 1px solid var(--light);
  }
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:not(.collapsed)::after svg {
  fill: var(--white);
}
// END Accordion CSS

.selected_chips_group {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.list_selection_panel {
  padding: 10px;
  max-height: 200px;
}

mat-header-row {
  min-height: 40px !important;
}

.sava_as_btn {
  min-width: 160px;
}

.more_btn,
.less_btn {
  min-width: 100px;
}

.pic_options {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5em;
}

.mdc-tooltip {
  .mdc-tooltip_surface {
    background: var(--tooltip-bg) !important;
    padding: 10px !important;
  }
}

// ng-select
.ng-select-container {
  display: block;
  width: 100%;
  height: inherit;
  padding: 0.54688rem 0.875rem;
  font-size: var(--body-font-size);
  font-weight: var(--fw-5) !important;
  line-height: 2.036;
  color: var(--text-color-dark) !important;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0.0625rem solid var(--color-4);
  border-radius: 0.5rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ng-select {
  .ng-select-container {
    min-height: 42px !important;
    min-width: 200px;
    background: var(--fc-background) !important;
    font-weight: var(--fw-6);
    border-radius: var(--fc-border-radius) !important;
    border: var(--fc-border) !important;
    outline: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: none !important;
    padding: 0.5em 1em;

    .ng-select-focus {
      color: var(--text-color-dark);
      background-color: var(--white);
      border-color: rgba(var(--primary-rgb), 0.4);
      outline: 0;
      box-shadow: 0 0 10px rgba(var(--primary-rgb), 0.1);
    }

    .ng-value-container {
      padding: 0px;
      margin-right: 10px;

      .ng-value {
        font-size: var(--body-font-size) !important;

        .status-icon {
          position: relative;
          bottom: var(--size-1);
        }
      }

      .ng-placeholder {
        font-size: 0.785rem;
        font-weight: normal;
        margin-right: 8px;
        color: var(--fc-input-placeholder) !important;
      }
    }
  }

  .ng-dropdown-panel {
    width: 100%;
    box-shadow: var(--fc-box-shadow);
    background-color: var(--white);
    border: var(--fc-border);
    border-top-left-radius: 0px;
    margin-top: -1px;
    padding-bottom: 5px;
    top: auto;
    left: 0;

    .ng-dropdown-panel-items {
      padding: 10px;
      max-height: 240px !important;
      box-sizing: border-box;
      display: block;
      height: auto;
      overflow-y: auto;

      .ng-option {
        border-radius: var(--fc-select-open-border-radius);
        padding: 0.5rem;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        word-wrap: break-word;
        width: 100%;

        &:hover {
          background: rgba(var(--primary-rgb), 0.06) !important;
        }

        &:focus {
          background: rgba(var(--primary-rgb), 0.06) !important;
        }

        &:visited {
          background: rgba(var(--primary-rgb), 0.06) !important;
        }

        &.ng-option-marked {
          background-color: rgba(var(--primary-rgb), 0.06) !important;
        }

        &.ng-option-selected {
          background-color: rgba(var(--primary-rgb), 0.2) !important;
          margin: 0.2em auto;
        }
      }
    }

    &.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
      border-bottom-right-radius: var(
        --fc-select-open-border-radius
      ) !important;
      border-bottom-left-radius: var(--fc-select-open-border-radius) !important;
    }
  }

  .ng-arrow-wrapper {
    width: auto;
    padding: 0px;
  }
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-bottom-right-radius: var(--fc-select-open-border-radius) !important;
  border-bottom-left-radius: var(--fc-select-open-border-radius) !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--fc-select-open-border-radius) !important;
  border-top-left-radius: var(--fc-select-open-border-radius) !important;
}

/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: var(--danger) !important;
}

/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: var(--danger) transparent transparent;
}

/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: var(--danger) !important;
}

.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      padding-top: 0 !important;
      padding-left: 0 !important;

      .ng-value {
        margin-bottom: 0px;
      }

      .ng-placeholder {
        position: relative !important;
        top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
      }
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0.5rem;
  left: 0;
  padding-left: 1rem;
  padding-right: 50px;
  text-transform: capitalize;
}

ng-select .ng-select-container .ng-value-container .ng-input > input {
  caret-color: transparent;
}
// END ng-select

.mat-mdc-tab-label-container {
  background-color: var(--background);
  .mdc-tab__text-label {
    font-family: var(--font-family);
  }
  .mat-mdc-tab.mdc-tab--active {
    .mdc-tab__text-label {
      font-weight: (var(--fw-8));
      color: var(--primary) !important;
    }
    .mdc-tab-indicator__content--underline {
      border-color: var(--primary) !important;
    }
  }
}

.wallet-card,
.service-cards {
  min-height: 200px;
  padding: 1.5rem !important;
  transition: box-shadow 0.1s ease-in-out;
  border: 1px solid var(--secondary-light) !important;

  &:hover {
    border: 1px solid var(--primary) !important;
    box-shadow: 0px 16px 24px 0px rgba(7, 18, 63, 0.25);
  }
  .card-body {
    display: flex;
    flex-direction: column;
    gap: var(--gap-10);
    padding-bottom: var(--gap-10);
  }
  .card-footer {
    padding-top: var(--gap-10);
  }
}

.service_icon_name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow-wrap: anywhere;

  .service_icon {
    background-image: url(../public/images/svg/service_icon_bg.svg);
    background-position: center;
    min-width: 3.75rem;
    height: 3.75rem;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 1.875rem;
      height: 1.875rem;
      font-size: 1.875rem;
      color: var(--primary);
    }
  }
}

.content .sec_gap_16:first-child {
  padding-bottom: var(--gap-16);
}

.sa_listing,
.sec_gap_16 {
  display: flex;
  flex-direction: column;
  gap: var(--gap-16);
}

.checkgroup label {
  color: var(--secondary);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.multi_badges {
  display: inline-flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  max-width: 250px;
}

.search {
  width: 100%;
  max-width: 25rem;
  position: relative;

  .icon {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    width: 100%;
    font: 600 1rem/1.125rem "Nunito Sans", sans-serif;
    padding: 1rem 1rem 1rem 2.75rem;
  }
}

.avatar-none {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  background: var(--background);
}

.service-badge {
  background-color: #f1e9f4;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 11px;
}

.mdc-form-field--align-end > label {
  width: 100%;
  display: inline-block;
}

.no_data_sec {
  background: var(--gray-100);
  padding: 3.12rem;
  width: auto;
  margin: auto;
}

.srvc_act_tabs {
  .mat-mdc-tab-header {
    border-radius: 0.438rem 0.438rem 0 0;
  }
  .mat-mdc-tab-body-wrapper {
    border-radius: 0 0 0.438rem 0.438rem;
    border: 1px solid var(--secondary-light);
    padding: 1.25rem;
  }

  .table-wrapper {
    // max-height: 300px;
    overflow: auto;
  }

  .table-head {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &--edit {
    .mat-mdc-tab-body-wrapper {
      background-color: var(--white);
    }
  }
}

// Mat Accordion CSS

.mat-accordion {
  .mat-expansion-panel {
    background: transparent !important;
  }

  .mat-expanded {
    background: var(--gray-100) !important;
  }
}

.action_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rate_cards_sec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap-16);
}
.list-item {
  border-bottom: 1px solid var(--secondary-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-10) 0;
  font-size: 0.8125rem;
  font-weight: 400;

  .item_price {
    font-weight: 800;
  }
}

// Temparory CSS
.chart-section {
  display: none;
}

.mat-expansion-panel-header-title {
  font-weight: 600;
}

//Reseller CSS
.welcome_text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cst_welcome_text {
  font-size: 1.4rem;
}

.org_name {
  color: var(--primary);
}

.view-role {
  .view_role_card {
    background-color: var(--gray-100);
    padding: 1rem;
  }
}

.mat-drawer-container {
  z-index: 9 !important;
}

.org_name {
  color: var(--primary);
}

// Update Rate Card Table CSS
.updateRCTable {
  overflow: auto;
  height: calc(100vh - 200px);

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .input-group {
    .input-group-prepend {
      padding: 0 0.4rem !important;
    }
    .bi {
      display: flex;
    }

    .form-control {
      padding: 0.5em 2em !important;
    }
  }
}
